import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Divider, Grid, Row } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Modal from 'rsuite/Modal';
import styled from 'styled-components';

import { notify, OpenModal } from '../../../../containers/styled/alerts';
import { PrimaryButton, PrimaryHoloButton } from '../../../../containers/styled/buttons';
import { TextField } from '../../../../containers/styled/inputs';
import { WarningSpan } from '../../../../containers/styled/layout';
import { SelectPickerField } from '../../../../containers/styled/styled';
import { Label } from '../../../../containers/styled/typography';
import { useDatabase } from '../../../../context/database';
import { createDonationThreshold } from '../../../../controllers/donation/donation-trheshold';
import { listFacility } from '../../../../controllers/facilities/FacilityController';
import { listBloodProductTypes } from '../../../../controllers/reports/CommonController';
import { SelectDiv as bseeSelectedDiv } from '../../../generic/styled';
import TableView from './TableView';

const SelectDiv = styled(bseeSelectedDiv)`
  .select-md .rs-picker-toggle.rs-btn {
    height: 58px !important;
  }
`;

const messages = defineMessages({
  placeholder: {
    id: 'configuration.donation.threshold.form.placeholder',
    defaultMessage: 'Enter Name of Consumables'
  },

  minQuantityPlaceholder: {
    id: 'configuration.donation.threshold.minqunatity.form.placeholder',
    defaultMessage: 'Min Quantity'
  },

  maxQuantityPlaceholder: {
    id: 'configuration.donation.threshold.maxqunatity.form.placeholder',
    defaultMessage: 'Max Quantity'
  },

  facilityPlaceholder: {
    id: 'configuration.donation.threshol.placeholder',
    defaultMessage: 'Select Facility'
  },

  bloodProductTypePlaceholder: {
    id: 'configuration.donation.threshol.bloodProductType.form.placeholder',
    defaultMessage: 'Select Blood Product type'
  },

  consumableFormTitle: {
    id: 'consumable.form.title',
    defaultMessage: 'Create donation threshold'
  },

  // CREATE
  createTitle: {
    id: 'configuration.donation.threshold.create.title',
    defaultMessage: 'Create donation threshold'
  },

  createHeader: {
    id: 'configuration.donation.threshold.form.create.header',
    defaultMessage: 'Created'
  },

  createSuccess: {
    id: 'configuration.donation.threshold.form.create.success',
    defaultMessage: 'Donation scheduled created Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'configuration.donation.threshold.form.update.title',
    defaultMessage: 'Edit donation threshold'
  },

  updateHeader: {
    id: 'configuration.donation.threshold.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'configuration.donation.threshold.form.update.success',
    defaultMessage: 'Donation scheduled Updated Successfully'
  },

  failed: {
    id: 'configuration.donation.threshold.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'configuration.donation.threshold.form.save.failed',
    defaultMessage: 'Could not save Facility Type'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'configuration.donation.threshold.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'configuration.donation.threshold.form.name.error.exists',
    defaultMessage: 'Facility Type already exists'
  },
  nameMaxLengthError: {
    id: 'configuration.donation.threshold.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

/**
 * Consumable Form screen
 *
 * Consumable form to create / update categories.
 *
 * @component
 * @param {object} item                prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose        handles modal close event
 * @returns type form modal.
 *
 */
const ConsumableForm = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();

  const [data, setData] = React.useState({
    id: '',
    maximum: 0,
    minimum: 0,
    facility: {
      id: '',
      name: ''
    },
    bloodProductType: {
      id: '',
      name: ''
    }
  });

  // const [isDisbaled, setIsDisabled] = React.useState(true);
  const [facilityData, setFacilityData] = React.useState([]);
  const [bloodProductTypeData, setBloodProductTypeData] = React.useState([]);

  const [tableData, setTableData] = React.useState([]);

  const submit = (event) => {
    event.stopPropagation();

    let header;

    Promise.all(
      tableData.map((item) =>
        createDonationThreshold(db, item)
          .then((done) => {
            if (done) {
              console.log('Donation schedule successfully!');
            }
            return done; // Return the result to the array of promises
          })
          .catch((error) => {
            console.error('Error creating donation threshold:', error);
            return false; // Return false if there's an error
          })
      )
    )
      .then((results) => {
        const allSuccessful = results.every((result) => result === true);
        const type = allSuccessful ? 'success' : 'error';
        notify(
          allSuccessful
            ? formatMessage(messages.createSuccess)
            : formatMessage(messages.saveFailed),
          header,
          type
        );
        handleClose();
      })
      .catch((error) => {
        console.error('Error in processing all promises:', error);
        notify(formatMessage(messages.saveFailed), header, 'error');
        handleClose();
      });
  };

  const addTotbale = () => {
    setTableData([...tableData, data]);
    setData({
      ...data,
      maximum: 0,
      minimum: 0,
      bloodProductType: {
        id: '',
        name: ''
      }
    });
  };

  React.useEffect(() => {
    // getting consumable item list
    listFacility(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({ value: entry.id, label: entry.name, type: { id: entry.typeId, name: entry.typeName } }));
      setFacilityData(docs);
    });
  }, [db]);

  React.useEffect(() => {
    // getting consumable item list
    listBloodProductTypes(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({ value: entry.id, label: entry.name }));
      setBloodProductTypeData(docs);
    });
  }, [db]);

  // const isAnyFieldEmpty = React.useCallback(() => {
  //   // Check top-level fields
  //   if (!data.maximum || !data.minimum) {
  //     return true;
  //   }

  //   // Check nested fields in item
  //   if (!data.facility.id) {
  //     return true;
  //   }
  //   // Check nested fields in item
  //   if (!data.bloodProductType.id) {
  //     return true;
  //   }

  //   return false;
  // }, [data]);

  // React.useEffect(() => {
  //   // Check top-level fields
  //   if (!data.maximum || !data.minimum) {
  //     setIsDisabled(true);
  //   } else if (!data.facility.id) {
  //     setIsDisabled(true);
  //   } else if (!data.bloodProductType.id) {
  //     setIsDisabled(true);
  //   } else {
  //     setIsDisabled(false);
  //   }
  // }, [data]);

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(!data.id ? messages.createTitle : messages.updateTitle)}

    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='configuration.donation.threshold.name.title'
                  defaultMessage='Facility'
                />
                <WarningSpan>*</WarningSpan>
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={data.facility.id}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.facilityPlaceholder)}
                  data={facilityData}
                  onChange={(value) => {
                    const facility = facilityData.find(item => item.value === value);
                    if (facility) {
                      setData({
                        ...data,
                        facility: {
                          id: facility.value,
                          name: facility.label,
                          type: facility.type // Assuming you need this field in your state
                        }
                      });
                    }
                  }}
                />
              </SelectDiv>
              <Divider />
            </Col>

            {tableData.length > 0 && (
              <Col xs={24}>
                <TableView tableData={tableData} />
                <Divider />
              </Col>
            )}

            <Col md={8}>
              <Label>
                <FormattedMessage
                  id='configuration.donation.threshold.bloodProductType.title'
                  defaultMessage='Blood Product Type'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <SelectDiv>
                <SelectPickerField
                  value={data.bloodProductType.id}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.bloodProductTypePlaceholder)}
                  data={bloodProductTypeData}
                  onChange={(value) => {
                    const bloodType = bloodProductTypeData.find(item => item.value === value);
                    setData({ ...data, bloodProductType: { id: bloodType.value, name: bloodType.label } });
                  }}
                />
              </SelectDiv>
            </Col>

            <Col md={8}>
              <Label>
                <FormattedMessage
                  id='configuration.donation.threshold.minQuantity.title'
                  defaultMessage='Min quantity'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.minimum}
                type='number'
                onChange={(value) => {
                  setData({ ...data, minimum: value });
                }}
                placeholder={formatMessage(messages.minQuantityPlaceholder)}
              />
            </Col>

            <Col md={8}>
              <Label>
                <FormattedMessage
                  id='configuration.donation.threshold.maxQuantity.title'
                  defaultMessage='Max Quantity'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.maximum}
                type='number'
                onChange={(value) => {
                  setData({ ...data, maximum: value });
                }}
                placeholder={formatMessage(messages.maxQuantityPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <FlexboxGrid justify='end' style={{ marginTop: '50px' }}>
                <FlexboxGrid.Item colspan={24}>
                  <PrimaryHoloButton
                    onClick={addTotbale}
                    style={{ width: '100%' }}
                  >
                    <FormattedMessage
                      id='configuration.donation.threshold.form.submit'
                      defaultMessage='Add more'
                    />
                  </PrimaryHoloButton>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>

            <Col md={12}>
              <FlexboxGrid justify='end' style={{ marginTop: '50px' }}>
                <FlexboxGrid.Item colspan={24}>
                  <PrimaryButton onClick={submit} style={{ width: '100%' }} disabled={tableData.length === 0}>
                    <FormattedMessage
                      id='configuration.donation.threshold.form.submit'
                      defaultMessage='Submit'
                    />
                  </PrimaryButton>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>

          </Row>

        </Grid>
      </Modal.Body>
    </OpenModal>
  );
};

export default ConsumableForm;
