import { defineMessages } from 'react-intl';

export const routeMessages = defineMessages({
  home: {
    id: 'navbar.home',
    defaultMessage: 'Home'
  },
  inventoryGroup: {
    id: 'navbar.group.inventory',
    defaultMessage: 'Inventory'
  },
  inventory: {
    id: 'navbar.group.inventory',
    defaultMessage: 'Inventory'
  },
  bloodProductTransfer: {
    id: 'navbar.group.inventory.bloodProductTransfer',
    defaultMessage: 'Blood product transfer'
  },
  configurationGroup: {
    id: 'navbar.group.configuration',
    defaultMessage: 'Configurations'
  },
  donationSchedule: {
    id: 'navbar.group.donationSchedule',
    defaultMessage: 'Donation schedules'
  },
  bloodProductStockLevel: {
    id: 'navbar.group.donationThreshold',
    defaultMessage: 'Donation Threshold'
  },
  consumableGroup: {
    id: 'navbar.group.consumables',
    defaultMessage: 'Consumables'
  },
  consumable: {
    id: 'navbar.consumable',
    defaultMessage: 'Consumable'
  },
  consumableTransfer: {
    id: 'navbar.consumables.transfer',
    defaultMessage: 'Consumable transfer'
  },
  dashboard: {
    id: 'navbar.dashboard',
    defaultMessage: 'Dashboard'
  },
  facilitiesGroup: {
    id: 'navbar.group.facilities',
    defaultMessage: 'Facilities'
  },

  facility: {
    id: 'navbar.group.facility',
    defaultMessage: 'Facility'
  },
  facilityType: {
    id: 'navbar.group.facility.type',
    defaultMessage: 'Facility Type'
  },
  facilityCategory: {
    id: 'navbar.group.facility.category',
    defaultMessage: 'Facility Category'
  },
  role: {
    id: 'navbar.group.role',
    defaultMessage: 'Roles'
  },
  user: {
    id: 'navbar.group.user',
    defaultMessage: 'Users'
  },
  reportsGroup: {
    id: 'navbar.group.reports',
    defaultMessage: 'Reports'
  },
  donor: {
    id: 'navbar.group.reports.donor',
    defaultMessage: 'Donors'
  },
  appointment: {
    id: 'navbar.group.reports.appointment',
    defaultMessage: 'Appointments'
  },
  donation: {
    id: 'navbar.group.reports.bloodDonation',
    defaultMessage: 'Blood Donations'
  },
  test: {
    id: 'navbar.group.reports.testing',
    defaultMessage: 'Testing'
  },
  processing: {
    id: 'navbar.group.reports.processing',
    defaultMessage: 'Processing'
  },
  delivery: {
    id: 'navbar.group.reports.deliveries',
    defaultMessage: 'Deliveries'
  },
  patient: {
    id: 'navbar.group.reports.patients',
    defaultMessage: 'Patients'
  },
  request: {
    id: 'navbar.group.reports.request',
    defaultMessage: 'Requests'
  },
  crossMatching: {
    id: 'navbar.group.reports.crossMatching',
    defaultMessage: 'Cross Matching'
  },
  adverseEvent: {
    id: 'navbar.group.reports.adverseEvent',
    defaultMessage: 'Adverse Event'
  }
});
