import { FormattedMessage } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import Modal from 'rsuite/Modal';

import { OpenModal } from '../../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../../containers/styled/layout';
import { ViewLabel, ViewLabelValue } from '../../../../containers/styled/typography';
import TableView from './TableView';

/**
 * Facility Type View screen
 *
 * Facility type view to display each type in-detail.
 *
 * @component
 * @param {object} item                Facility Type data
 * @param {boolean} handleClose        handles modal close event
 * @returns type view modal.
 *
 */
const DonationThresholdView = ({ item, handleClose }) => {
  return (
    <OpenModal
      data-testid='view-donation-threshold'
      onClose={handleClose}
      size='md'
      title={
        <FormattedMessage
          id='configuration.donation.threshold.details.title'
          defaultMessage='Donation threshold Details'
        />
      }
    >
      <Modal.Body style={{ marginBottom: 30 }}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='configuration.donation.threshold.view.label.facility'
                  defaultMessage='Facility'
                />
              </ViewLabel>
              <ViewLabelValue>{item.name}</ViewLabelValue>
            </Col>
            <Col xs={6}>
              <ViewLabel>
                <FormattedMessage
                  id='configuration.donation.threshold.view.label.type'
                  defaultMessage='Facility Type'
                />
              </ViewLabel>
              <ViewLabelValue>{item.type}</ViewLabelValue>
            </Col>
          </Row>
          <ViewMarginDiv />
          <Row>
            <Col xs={24}>
              <TableView tableData={item.data} />
            </Col>
          </Row>
          <ViewMarginDiv />
        </Grid>
      </Modal.Body>
    </OpenModal>
  );
};

export default DonationThresholdView;
