import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationNumberValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for retention sample data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listRetentionSamples(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        status
        volume
        details

        bloodTestingCenter { id name address }
        donation {
          id
          icode
          dateTime
          volume
          bloodDonationCenter { id name address stockUnit { id name address } }
          donor { id donorCode fullName personName gender bloodType }
        }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for retention sample data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertRetentionSample(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationNumberValue(doc, 'volume')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'bloodTestingCenter')}
          ${getMutationNestedValue(doc, 'donation')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize retention sample data with GraphQL endpoint
 */
export const syncRetentionSample = (...args) => {
  return buildReplication(
    ...args,
    'retentionSample',
    { pullBuilder, pullQuery: 'listRetentionSamples' },
    { pushBuilder }
  );
};
