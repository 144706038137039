import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Divider, Grid, Row } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Modal from 'rsuite/Modal';
import styled from 'styled-components';

import { notify, OpenModal } from '../../../containers/styled/alerts';
import { PrimaryButton, PrimaryHoloButton } from '../../../containers/styled/buttons';
import { TextField } from '../../../containers/styled/inputs';
import { WarningSpan } from '../../../containers/styled/layout';
import { SelectPickerField } from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import {
  listConsumable,
  transferConsumables,
  updateConsumable
} from '../../../controllers/consumable/consumable';
import { listFacility } from '../../../controllers/facilities/FacilityController';
import { SelectDiv as bseeSelectedDiv } from '../../generic/styled';

const SelectDiv = styled(bseeSelectedDiv)`
  .select-md .rs-picker-toggle.rs-btn {
    height: 58px !important;
  }
`;
const messages = defineMessages({
  placeholder: {
    id: 'consumables.consumable.form.placeholder',
    defaultMessage: 'Enter Name of Consumables'
  },

  quantityPlaceholder: {
    id: 'consumables.transfer.quantity.form.placeholder',
    defaultMessage: 'Quantity'
  },

  facilityPlaceholder: {
    id: 'consumables.transfer.facility.placeholder',
    defaultMessage: 'Receiving Facility'
  },
  cansumablePlaceholder: {
    id: 'consumables.transfer.placeholder',
    defaultMessage: 'Select consumables'
  },

  consumableFormTitle: {
    id: 'consumable.transfer.form.title',
    defaultMessage: 'Transfer consumable'
  },

  // CREATE
  createTitle: {
    id: 'consumables.transfer.consumable.create.title',
    defaultMessage: 'Transfer consumable'
  },

  createHeader: {
    id: 'consumables.transfer.consumable.form.create.header',
    defaultMessage: 'Created'
  },

  createSuccess: {
    id: 'consumables.transfer.consumable.form.create.success',
    defaultMessage: 'Consumables Transfer Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'consumables.consumable.form.update.title',
    defaultMessage: 'Edit Consumable'
  },

  updateHeader: {
    id: 'consumables.consumable.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'consumables.consumable.form.update.success',
    defaultMessage: 'Consumable Updated Successfully'
  },

  failed: {
    id: 'consumables.consumable.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'consumables.consumable.form.save.failed',
    defaultMessage: 'Could not save Facility Type'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'consumables.consumable.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'consumables.consumable.form.name.error.exists',
    defaultMessage: 'Facility Type already exists'
  },
  nameMaxLengthError: {
    id: 'consumables.consumable.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

/**
 * Consumable Form screen
 *
 * Consumable form to create / update categories.
 *
 * @component
 * @param {object} item                prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose        handles modal close event
 * @returns type form modal.
 *
 */
const ConsumableTransferForm = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();

  const [facilities, setFacilities] = React.useState([]);
  const [consumables, setConsumables] = React.useState([]);

  // const [tableData, setTableData] = React.useState([]);

  const [data, setData] = React.useState({
    id: '',
    facility: {
      id: '',
      name: ''
    },
    consumable: {
      id: '',
      name: '',
      quantity: 0
    },
    manufacturerDate: null,
    expiryDate: null,
    serialNumber: '',
    name: '',
    quantity: 1
  });

  const [isDisbaled, setIsDisabled] = React.useState(true);

  const submit = (event) => {
    event.stopPropagation();

    let action;
    let header;
    let message;

    if (!data.id) {
      action = transferConsumables;
      header = formatMessage(messages.createHeader);
      message = formatMessage(messages.createSuccess);
    } else {
      action = updateConsumable;
      header = formatMessage(messages.updateHeader);
      message = formatMessage(messages.updateSuccess);
    }

    action(db, data).then((done) => {
      notify(
        done ? message : formatMessage(messages.saveFailed),
        header,
        done ? 'success' : 'error'
      );
      handleClose();
    });
  };

  React.useEffect(() => {
    // getting consumable item list
    listFacility(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({ value: entry.id, label: entry.name }));
      setFacilities(docs);
    });
  }, [db]);

  React.useEffect(() => {
    // getting consumable item list
    listConsumable(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({ value: entry.id, label: entry.name, quantity: entry.quantity }));
      setConsumables(docs);
    });
  }, [db]);

  const isAnyFieldEmpty = React.useCallback(() => {
    // Check top-level fields
    if (!data.quantity) {
      return true;
    }

    if (!data.facility.id || !data.consumable.id) {
      return true;
    }
    return false;
  }, [data]);

  React.useEffect(() => {
    const isSubmitEnable = isAnyFieldEmpty();
    setIsDisabled(isSubmitEnable);
  }, [data, isAnyFieldEmpty]);

  const addMore = () => {
    // const tData = {
    //   facility: data.facility,
    //   facilityName: data.facility.name,
    //   consumable: data.consumable,
    //   consumableName: data.consumable.name,
    //   avlQty: data.consumable.quantity,
    //   reQty: data.quantity,
    //   clear: 'crear'
    // };
    // setTableData(prevTableData => [...prevTableData, tData]);
    console.log('added to table');
  };

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(messages.createTitle)}
    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.transfer.consumable.name.title'
                  defaultMessage='Receiving facility'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <SelectDiv>
                <SelectPickerField
                  value={data.facility.id || ''}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.facilityPlaceholder)}
                  data={facilities}
                  onChange={(value) => {
                    const consuTtem = facilities.find(item => item.value === value);
                    setData({ ...data, facility: { name: consuTtem.label, id: value } });
                  }}
                />
              </SelectDiv>
            </Col>

            <Col xs={24}>
              {/* {tableData.length > 0 && (
                <>
                  <TableFormView tableData={tableData} />
                  <Divider />
                </>
              )} */}
              <Divider />
            </Col>

            <Col md={8}>
              <Label>
                <FormattedMessage
                  id='consumables.transfer.consumable.name.title'
                  defaultMessage='Consumable'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <SelectDiv>
                <SelectPickerField
                  value={data.consumable.id || ''}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.cansumablePlaceholder)}
                  data={consumables}
                  onChange={(value) => {
                    const consuTtem = consumables.find(item => item.value === value);
                    setData({
                      ...data,
                      consumable: {
                        name: consuTtem.label,
                        id: value,
                        quantity: consuTtem.quantity
                      }
                    });
                  }}
                />
              </SelectDiv>
            </Col>

            <Col md={8}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.quantity.avl.title'
                  defaultMessage='Available Qty'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.consumable.quantity}
                disabled
                placeholder={formatMessage(messages.quantityPlaceholder)}
              />
            </Col>

            <Col md={8}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.quantity.required.title'
                  defaultMessage='Quantity'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.quantity}
                placeholder={formatMessage(messages.quantityPlaceholder)}
                onChange={(value) => {
                  setData({ ...data, quantity: value });
                }}
              />
            </Col>

            <Col md={12}>
              <FlexboxGrid justify='end' style={{ marginTop: '50px' }}>
                <FlexboxGrid.Item colspan={24}>
                  <PrimaryHoloButton
                    onClick={addMore}
                    style={{ width: '100%' }}
                  >
                    <FormattedMessage
                      id='consumables.consumable.form.submit'
                      defaultMessage='Add more'
                    />
                  </PrimaryHoloButton>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>

            <Col md={12}>
              <FlexboxGrid justify='end' style={{ marginTop: '50px' }}>
                <FlexboxGrid.Item colspan={24}>
                  <PrimaryButton onClick={submit} style={{ width: '100%' }} disabled={isDisbaled}>
                    <FormattedMessage
                      id='consumables.consumable.form.submit'
                      defaultMessage='Submit'
                    />
                  </PrimaryButton>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>

          </Row>
        </Grid>
      </Modal.Body>
    </OpenModal>
  );
};

export default ConsumableTransferForm;
