import appointmentCollection from './blood-donors/appointment';
import donationCollection from './blood-donors/donation';
import donorCollection from './blood-donors/donor';
import representativeCollection from './blood-donors/representative';
import consumableCollection from './consumables/consumable';
import consumableItemCollection from './consumables/consumable-item';
import consumableItemCategoryCollection from './consumables/consumable-item-category';
import consumableTransferCollection from './consumables/consumable-transfer';
import equipmentCollection from './equipments/equipment';
import facilityCollection from './facilities/facility';
import facilityCategoryCollection from './facilities/facility-category';
import facilityTypeCollection from './facilities/facility-type';
import bloodProductCollection from './laboratory/blood-product';
import bloodProductStockLevelCollection from './laboratory/blood-product-stock-level';
import bloodProductTranferCollection from './laboratory/blood-product-transfer';
import bloodProductTypeCollection from './laboratory/blood-product-type';
import processingCollection from './laboratory/processing';
import resultCollection from './laboratory/result';
import retentionSampleCollection from './laboratory/retention-sample';
import testCollection from './laboratory/test';
import testTypeCollection from './laboratory/test-type';
import wastageCollection from './laboratory/wastage';
import collectionTaskCollection from './logistics/collection-task';
import collectionTaskConfirmationCollection from './logistics/collection-task-confirmation';
import crisisEventCollection from './logistics/crisis-event';
import deliveryCollection from './logistics/delivery';
import deliveryConfirmationCollection from './logistics/delivery-confirmation';
import requestCollection from './logistics/request';
import restrictedBloodUsageCollection from './logistics/restricted-blood-usage';
import vehicleCollection from './logistics/vehicle';
import adverseEventCollection from './patients/adverse-event';
import crossMatchingCollection from './patients/cross-matching';
import patientCollection from './patients/patient';
import transfusionCollection from './patients/transfusion';
import ethnicGroupCollection from './persons/ethnic-group';
import languageCollection from './persons/language';
import questionnaireCollection from './questionnaires/questionnaire';
import questionnaireAnswerCollection from './questionnaires/questionnaire-answer';
import authCollection from './ums/auth';
import roleCollection from './ums/role';
import userCollection from './ums/user';

const getKey = (library, model) => `${library.toLowerCase()}__${model.toLowerCase()}`;

const keys = {
  // authentication and authorization
  auth: getKey('auth', 'login'),

  // users (drivers and health workers)
  user: getKey('ums', 'user'),
  role: getKey('ums', 'role'),

  // persons
  ethnicGroup: getKey('persons', 'EthnicGroup'),
  language: getKey('persons', 'LanguageDialect'),

  // logistic items

  item: getKey('consumables', 'Item'),
  itemCategory: getKey('consumables', 'ItemCategory'),

  consumable: getKey('consumables', 'Consumable'),
  consumableTransfer: getKey('consumables', 'ConsumableTransfer'),
  equipment: getKey('equipments', 'Equipment'),
  vehicle: getKey('logistics', 'Vehicle'),

  // facilities
  facility: getKey('facilities', 'Facility'),
  facilityCategory: getKey('facilities', 'FacilityCategory'),
  facilityType: getKey('facilities', 'FacilityType'),

  // blood donors
  appointment: getKey('blood_donors', 'Appointment'),
  donation: getKey('blood_donors', 'Donation'),
  donor: getKey('blood_donors', 'Donor'),
  representative: getKey('blood_donors', 'BloodDonorRepresentative'),

  // questionnaires
  questionnaire: getKey('questionnaires', 'Questionnaire'),
  questionnaireAnswer: getKey('questionnaires', 'QuestionnaireAnswer'),

  // laboratory
  result: getKey('laboratory', 'Result'),
  retentionSample: getKey('laboratory', 'RetentionSample'),
  test: getKey('laboratory', 'Test'),
  testType: getKey('laboratory', 'TestType'),

  bloodProduct: getKey('laboratory', 'BloodProduct'),
  bloodProductType: getKey('laboratory', 'BloodProductType'),
  processing: getKey('laboratory', 'Processing'),
  wastage: getKey('laboratory', 'Wastage'),

  bloodProductStockLevel: getKey('laboratory', 'BloodProductStockLevel'),
  bloodProductSignOff: getKey('laboratory', 'BloodProductSignOff'),

  // logistic activities
  collectionTask: getKey('logistics', 'CollectionTask'),
  collectionTaskConfirmation: getKey('logistics', 'CollectionConfirmation'),
  delivery: getKey('logistics', 'Delivery'),
  deliveryConfirmation: getKey('logistics', 'DeliveryConfirmation'),
  request: getKey('logistics', 'Request'),

  crisisEvent: getKey('logistics', 'CrisisEvent'),
  restrictedBloodUsage: getKey('logistics', 'RestrictedBloodUsage'),

  // patients
  adverseEvent: getKey('patients', 'AdverseEvent'),
  crossMatching: getKey('patients', 'CrossMatching'),
  patient: getKey('patients', 'Patient'),
  transfusion: getKey('patients', 'Transfusion')
};

/**
 * RxDB collection schemas
 */
export const collections = {
  // authentication and authorization
  [keys.auth]: { ...authCollection },

  // users (drivers and health workers)
  [keys.user]: { ...userCollection },
  [keys.role]: { ...roleCollection },

  // persons
  [keys.ethnicGroup]: { ...ethnicGroupCollection },
  [keys.language]: { ...languageCollection },

  // facilities
  [keys.facility]: { ...facilityCollection },
  [keys.facilityCategory]: { ...facilityCategoryCollection },
  [keys.facilityType]: { ...facilityTypeCollection },

  // logistic items
  [keys.item]: { ...consumableItemCollection },
  [keys.itemCategory]: { ...consumableItemCategoryCollection },

  [keys.consumable]: { ...consumableCollection },
  [keys.consumableTransfer]: { ...consumableTransferCollection },

  [keys.equipment]: { ...equipmentCollection },
  [keys.vehicle]: { ...vehicleCollection },

  // blood donors
  [keys.appointment]: { ...appointmentCollection },
  [keys.representative]: { ...representativeCollection },
  [keys.donor]: { ...donorCollection },
  [keys.donation]: { ...donationCollection },

  // questionnaires
  [keys.questionnaire]: { ...questionnaireCollection },
  [keys.questionnaireAnswer]: { ...questionnaireAnswerCollection },

  // laboratory
  [keys.result]: { ...resultCollection },
  [keys.retentionSample]: { ...retentionSampleCollection },
  [keys.test]: { ...testCollection },
  [keys.testType]: { ...testTypeCollection },

  [keys.bloodProduct]: { ...bloodProductCollection },
  [keys.bloodProductType]: { ...bloodProductTypeCollection },
  [keys.processing]: { ...processingCollection },
  [keys.wastage]: { ...wastageCollection },
  [keys.bloodProductStockLevel]: { ...bloodProductStockLevelCollection },
  [keys.bloodProductSignOff]: { ...bloodProductTranferCollection },

  // logistic activities
  [keys.collectionTask]: { ...collectionTaskCollection },
  [keys.collectionTaskConfirmation]: { ...collectionTaskConfirmationCollection },
  [keys.delivery]: { ...deliveryCollection },
  [keys.deliveryConfirmation]: { ...deliveryConfirmationCollection },
  [keys.request]: { ...requestCollection },

  [keys.crisisEvent]: { ...crisisEventCollection },
  [keys.restrictedBloodUsage]: { ...restrictedBloodUsageCollection },

  // patients
  [keys.adverseEvent]: { ...adverseEventCollection },
  [keys.crossMatching]: { ...crossMatchingCollection },
  [keys.patient]: { ...patientCollection },
  [keys.transfusion]: { ...transfusionCollection }
};

/**
 * Get collection by key
 */
export const getCollection = (db, key) => db[keys[key]];
