import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../containers/styled/layout';
import { useDatabase } from '../../context/database';
import { deleteConsumable } from '../../controllers/consumable/consumable';
import { getCollection } from '../../rxdb/collections';
import { permissions } from '../../utils/auth';
import DataEntity from '../generic/DataEntity';
import FormComponent from './subcomponents/ConsumableForm';
import ViewComponent from './subcomponents/ConsumableView';

const messages = defineMessages({
  title: {
    id: 'consumables.consumable.title',
    defaultMessage: 'Consumables'
  },
  count: {
    id: 'Consumables.consumable.count.label',
    defaultMessage: 'Consumables(s)'
  },
  searchPlaceHolder: {
    id: 'consumables.consumable.search.placeholder',
    defaultMessage: 'Search'
  },
  name: {
    id: 'consumable.name',
    defaultMessage: 'Consumables'
  },
  quantity: {
    id: 'consumable.quantity',
    defaultMessage: 'Available Qty (Pcs)'
  },
  serialNo: {
    id: 'consumable.serialNo',
    defaultMessage: 'Serial number'
  },
  manufacturerDate: {
    id: 'consumable.manufacturerDate',
    defaultMessage: 'Manufacture date'
  },
  expiryDate: {
    id: 'consumable.expiryDate',
    defaultMessage: 'Expiry date'
  },
  add: {
    id: 'consumables.consumable.add.button',
    defaultMessage: 'Create consumable'
  }
});

const ADD = permissions.consumable.add;
const EDIT = permissions.consumable.edit;
const DELETE = permissions.consumable.del;

/**
 * Facility Type screen
 *
 * List of available facility types.
 *
 * @component
 * @example
 * return (
 *    <Consumable />
 * );
 */
const Consumable = () => {
  const db = useDatabase();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const subscription = getCollection(db, 'consumable')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        const docs = entries.map((entry) => {
          return {
            id: entry.id,
            name: entry.name,
            serialNumber: entry.serialNumber,
            quantity: entry.quantity || 0,
            manufacturerDate: entry.manufacturerDate,
            expiryDate: entry.expiryDate,
            item: entry.item
          };
        });
        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  return (
    <MarginDiv data-testid='consumable'>
      <DataEntity
        crumbs={['consumableGroup', 'consumable']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'serialNumber', label: messages.serialNo },

          { dataKey: 'quantity', label: messages.quantity },
          { dataKey: 'manufacturerDate', label: messages.manufacturerDate },
          { dataKey: 'expiryDate', label: messages.expiryDate }
        ]}
        addPerm={ADD}
        editPerm={EDIT}
        FormComponent={FormComponent}
        deletePerm={DELETE}
        onDelete={deleteConsumable}
        ViewComponent={ViewComponent}
        messages={messages}
      />

    </MarginDiv>
  );
};

export default Consumable;
