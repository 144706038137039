import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'rsuite';

import { ViewLabel } from '../../../../containers/styled/typography';

const { Column, HeaderCell, Cell } = Table;

const TableView = ({ tableData }) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (tableData.length === 0) return;
    const docs = tableData.map((item) => ({
      bloodProductType: item.bloodProductType.name,
      minQuantity: item.minimum,
      maxQuantity: item.maximum
    }));
    setData(docs);
  }, [tableData]);

  return (
    <div>
      <Table data={data}>

        <Column width={150} resizable>
          <HeaderCell>
            <ViewLabel>
              <FormattedMessage
                id='configuration.donation.threshold.view.label.bloodType'
                defaultMessage='Blood Product Type'
              />
            </ViewLabel>
          </HeaderCell>
          <Cell dataKey='bloodProductType' />
        </Column>

        <Column width={150} resizable>
          <HeaderCell>
            <ViewLabel>
              <FormattedMessage
                id='configuration.donation.threshold.view.label.minQuantity'
                defaultMessage='MIN Qunatity'
              />
            </ViewLabel>
          </HeaderCell>
          <Cell dataKey='minQuantity' />
        </Column>

        <Column width={150} resizable>
          <HeaderCell>
            <ViewLabel>
              <FormattedMessage
                id='configuration.donation.threshold.view.label.maxQuantity'
                defaultMessage='MAX Quantity'
              />
            </ViewLabel>
          </HeaderCell>
          <Cell dataKey='maxQuantity' />
        </Column>
      </Table>
    </div>
  );
};

export default TableView;
