import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  buildReplication,
  getMutationArrayNestedValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationNumberValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for donation data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listDonations(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        dateTime
        status
        details

        volume

        bloodDonationCenter { id name address stockUnit { id name address }}
        bloodProducts { id bloodProductType { id name } }
        bloodProductType { id name }

        donor { id donorCode fullName personName gender bloodType }

        consumables { id name }
        equipments { id name }
        healthCareWorkers { username name }

        height
        weight
        pulse
        hemoglobin
        bloodPressure

        isActive
        lastmodifiedAt
        createdAt
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for donation data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertDonation(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'dateTime')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationStringValue(doc, 'bloodType')}
          ${getMutationNestedValue(doc, 'bloodProductType')}

          ${getMutationNumberValue(doc, 'volume')}

          ${getMutationNumberValue(doc, 'height')}
          ${getMutationNumberValue(doc, 'weight')}
          ${getMutationNumberValue(doc, 'pulse')}
          ${getMutationNumberValue(doc, 'hemoglobin')}
          ${getMutationStringValue(doc, 'bloodPressure')}

          ${getMutationNestedValue(doc, 'bloodDonationCenter')}
          ${getMutationNestedValue(doc, 'donor')}

          ${getMutationArrayNestedValue(doc, 'consumableIds', 'consumables')}
          ${getMutationArrayNestedValue(doc, 'equipmentIds', 'equipments')}
          ${getMutationArrayNestedValue(doc, 'healthCareWorkerNames', 'healthCareWorkers', 'username')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize donation data with GraphQL endpoint
 */
export const syncDonation = (...args) => {
  return buildReplication(
    ...args,
    'donation',
    { pullBuilder, pullQuery: 'listDonations' },
    { pushBuilder }
  );
};
