import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  Dropdown,
  IconButton,
  Pagination,
  Popover,
  Table,
  Whisper
} from 'rsuite';

// import deleteIcon from '../../assets/svg-images/del-icon.svg';
// import editIcon from '../../assets/svg-images/edit-icon.svg';
import trashIcon from '../../assets/svg-images/trash.svg';
// import viewIcon from '../../assets/svg-images/view-icon.svg';
import { ConfirmModal, notify } from '../../containers/styled/alerts';
import {
  // ActionImage,
  FaIcon,
  HeaderSpan,
  WhiteDiv
} from '../../containers/styled/layout';
import { H4, Hr } from '../../containers/styled/typography';
import { useAuth } from '../../context/auth';
import { useDatabase } from '../../context/database';
import EmptyTable from './EmptyTable';
import {
  activeState,
  cancelledState,
  Chips,
  completedState,
  disabledState,
  DropdownItem,
  otherState,
  StyledCell,
  StyledPanel,
  StyledTable,
  upcomingState
} from './styled';

const messages = defineMessages({
  viewHeader: {
    id: 'table.action.view.header',
    defaultMessage: 'View details'
  },

  editHeader: {
    id: 'table.action.edit.header',
    defaultMessage: 'Edit'
  },

  deleteHeader: {
    id: 'table.action.delete.header',
    defaultMessage: 'Delete'
  },

  deleteTitle: {
    id: 'table.action.delete.title',
    defaultMessage: 'Action'
  },

  deleteSuccess: {
    id: 'table.action.delete.success',
    defaultMessage: 'One record deleted successfully'
  },

  bloodTransferHeader: {
    id: 'table.action.bloodTransfer.header',
    defaultMessage: 'Blood transfer'
  },

  failedMessage: {
    id: 'table.action.failed',
    defaultMessage: 'Something went wrong'
  }
});

/**
 * Data Table component
 *
 * Data table displays Data table
 *
 * @component
 * @param {array} data              an array of objects representing the data to be displayed
 * @param {array} columns           an array of objects representing the columns to be displayed in the data table
 * @param {string} deletePerm       a string representing the permission required to delete data
 * @param onDelete                  a function to be called when the user deletes a row from the data table
 * @param FormComponent             a react component to be displayed when the user wants to add or edit data
 * @param {string} editPerm         a string representing the permission required to edit data
 * @param ViewComponent             a react component to be displayed when the user clicks on a row in the data table
 * @param {boolean} isStatus        a boolean indicating whether the data table has a column for status
 * @example
 * return (
 *    <DataTable
 *      data={data}
 *      columns={columns}
 *      deletePerm={DELETE}
 *      editPerm={EDIT}
 *      FormComponent={Component}
 *      ViewComponent={Component}
 *    />
 * )
 *
 */
const DataTable = ({
  data,
  columns,
  isStatus,
  ViewComponent,
  editPerm,
  FormComponent,
  deletePerm,
  onDelete,
  bloodTransferPerm,
  hasViewPage,
  openNotherPage
}) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();
  const { auth } = useAuth();
  const hasEditPerm = editPerm ? auth.hasPermission(editPerm) : false;
  const hasDeletePerm = deletePerm ? auth.hasPermission(deletePerm) : false;
  const hasBloodTransferPerm = bloodTransferPerm ? auth.hasPermission(bloodTransferPerm) : false;

  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [pageData, setPageData] = React.useState([]);

  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
  };

  const handleClearSelection = () => {
    setShowDetails(false);
    setShowEditForm(false);
    setShowDeleteConfirm(false);
    setSelectedRow(null);
  };

  const remove = async () => {
    const done = await onDelete(db, selectedRow);
    handleClearSelection();

    notify(
      formatMessage(done ? messages.deleteSuccess : messages.failedMessage),
      formatMessage(messages.deleteHeader),
      done ? 'success' : 'error'
    );
  };

  const ActionCell = ({ rowData, dataKey, ...props }) => {
    const renderMenu = ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey) => {
        onClose();

        switch (eventKey) {
          case 2:
            // VIEW

            if (hasViewPage) {
              openNotherPage(rowData); // its not a popup
            } else {
              setSelectedRow(rowData);
              setShowDetails(true);
            }
            break;

          case 3:
            // EDIT
            if (!hasEditPerm) break;
            setSelectedRow(rowData);
            setShowEditForm(true);
            break;

          case 4:
            // DELETE
            if (!hasDeletePerm) break;
            setSelectedRow(rowData);
            setShowDeleteConfirm(true);
            break;

          case 5:
            // BLOOD TRANSFER
            if (!hasBloodTransferPerm) break;
            setSelectedRow(rowData);
            setShowEditForm(true);
            break;

          default:
            break;
        }
      };

      return (
        <Popover
          ref={ref}
          className={className}
          style={{ left, top, width: '12%' }}
          full
        >
          <Dropdown.Menu onSelect={handleSelect} style={{ padding: 0 }}>

            <DropdownItem eventKey={2}>
              {/* <ActionImage src={viewIcon} /> */}
              {formatMessage(messages.viewHeader)}
            </DropdownItem>

            {hasEditPerm && (
              <>
                <Hr />
                <DropdownItem eventKey={3}>
                  {/* <ActionImage src={editIcon} /> */}
                  {formatMessage(messages.editHeader)}
                </DropdownItem>
              </>
            )}

            {hasDeletePerm && (
              <>
                <Hr />
                <DropdownItem eventKey={4}>
                  {/* <ActionImage src={deleteIcon} /> */}
                  {formatMessage(messages.deleteHeader)}
                </DropdownItem>
              </>
            )}

            {hasBloodTransferPerm && (
              <>
                <Hr />
                <DropdownItem eventKey={5}>
                  {/* <ActionImage src={deleteIcon} /> */}
                  {formatMessage(messages.bloodTransferHeader)}
                </DropdownItem>
              </>
            )}
          </Dropdown.Menu>
        </Popover>
      );
    };

    return (
      <Table.Cell {...props}>
        <Whisper
          placement='autoVerticalEnd'
          trigger='click'
          speaker={renderMenu}
        >
          <IconButton
            size='xs'
            appearance='subtle'
            icon={<FaIcon icon='ellipsis-vertical' />}
          />
        </Whisper>
      </Table.Cell>
    );
  };

  const getStyle = (status) => {
    if (['active', 'discharged'].includes(status)) return activeState;
    if (['admitted', 'upcoming'].includes(status)) return upcomingState;
    if (['in-progress', 'scheduled'].includes(status)) return disabledState;
    if (['completed', 'ongoing'].includes(status)) return completedState;
    if (['cancelled'].includes(status)) return cancelledState;
    return otherState;
  };

  const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props}>
        <Chips style={getStyle(rowData.status.toLowerCase())}>
          {rowData.status}
        </Chips>
      </Table.Cell>
    );
  };

  React.useEffect(() => {
    const currentPage = data.filter((v, index) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return index >= start && index < end;
    });

    setPageData(currentPage);
  }, [data, limit, page]);

  return (
    <WhiteDiv>
      <StyledPanel>
        {data.length === 0
          ? (
            <EmptyTable />
            )
          : (
            <>
              <StyledTable headerHeight={36} height={(limit + 1) * 50} data={pageData}>
                {columns
                  .filter((column) => column.dataKey !== 'status')
                  .map((column) => (
                    <Table.Column
                      key={column.dataKey}
                      align={column.align || 'left'}
                      flexGrow={column.flex || 1}
                    >
                      <Table.HeaderCell style={{ padding: '2px 10px' }}>
                        <HeaderSpan>{formatMessage(column.label)}</HeaderSpan>
                      </Table.HeaderCell>

                      <StyledCell dataKey={column.dataKey} />
                    </Table.Column>
                  ))}

                {!!isStatus && (
                  <Table.Column align='left' width={105}>
                    <Table.HeaderCell style={{ padding: '0 10px' }}>
                      <HeaderSpan>
                        <FormattedMessage
                          id='table.header.status'
                          defaultMessage='STATUS'
                        />
                      </HeaderSpan>
                    </Table.HeaderCell>

                    <StatusCell dataKey='id' />
                  </Table.Column>
                )}

                <Table.Column align='right' width={100}>
                  <Table.HeaderCell style={{ padding: '0 10px' }}>
                    <HeaderSpan>
                      <FormattedMessage
                        id='table.header.action'
                        defaultMessage='ACTION'
                      />
                    </HeaderSpan>
                  </Table.HeaderCell>

                  <ActionCell dataKey='id' />
                </Table.Column>
              </StyledTable>

              <div>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size='xs'
                  layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                  total={data.length}
                  limitOptions={[5, 10, 20, 50]}
                  limit={limit}
                  activePage={page}
                  onChangePage={setPage}
                  onChangeLimit={handleChangeLimit}
                />
              </div>
            </>
            )}
      </StyledPanel>

      {showDetails && selectedRow && (
        <ViewComponent item={selectedRow} handleClose={handleClearSelection} />
      )}

      {(hasEditPerm || hasBloodTransferPerm) && showEditForm && selectedRow && (
        <FormComponent item={selectedRow} handleClose={handleClearSelection} />
      )}

      {hasDeletePerm && showDeleteConfirm && selectedRow && (
        <ConfirmModal
          onClose={handleClearSelection}
          onConfirm={remove}
          title={`${formatMessage(messages.deleteTitle)}`}
          message={
            <div style={{ textAlign: 'center' }}>
              <img
                src={trashIcon}
                alt='Trash'
                height='57'
                width='57'
                style={{ marginBottom: 10 }}
              />
              <H4>
                <FormattedMessage
                  id='table.action.delete.confirm'
                  defaultMessage='Are you sure you want to delete this record?'
                />
              </H4>
            </div>
          }
        />
      )}
    </WhiteDiv>
  );
};

export default DataTable;
