import ReactECharts from 'echarts-for-react';
import { groupBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { StyledChartsPanel } from '../../../containers/styled/styled';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { getCurrentMonthData } from '../../../utils';
import { StyledSubHeading } from '../styled';

const TestsByStatus = ({ title, statusChildData, onChartImageReady }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();
  const [keys, setKeys] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [passData, setPassData] = useState([]);
  const [failData, setFailData] = useState([]);
  const chartRef = useRef(null);

  const headingStyle = {
    color: '#010005',
    marginTop: 0
  };

  const handleChartFinished = () => {
    const chart = chartRef.current.getEchartsInstance();
    const chartImage = chart.getDataURL({ pixelRatio: 2 });
    onChartImageReady(chartImage);
  };

  useEffect(() => {
    const subscription = getCollection(db, 'test')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        const documents = getCurrentMonthData(entries).map((data) => {
          const createdAt = new Date(data.createdAt);
          const month = createdAt.toLocaleDateString('en-US', { month: 'short' });
          return {
            ...data,
            month: month.charAt(0).toUpperCase()
          };
        });

        const groupedData = groupBy(documents, 'month');
        setKeys(Object.keys(groupedData));
        const formattedData = Object.keys(groupedData).map((key) => {
          const passedData = groupedData[key].filter((data) => {
            return data._data.desktopResult === 'negative' && data._data.machineResult === 'negative';
          }).length;
          const failledData = groupedData[key].filter((data) => {
            return (data._data.desktopResult === 'positive' || data.machineResult === 'positive');
          }).length;

          return {
            key1: groupedData[key],
            pass: passedData,
            fail: failledData
          };
        });

        setChartData(formattedData);
        statusChildData({
          key: Object.keys(groupedData),
          values: formattedData
        });
      });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, [db]);

  useEffect(() => {
    setPassData(chartData.map((item) => item.pass));
    setFailData(chartData.map((item) => item.fail));
  }, [chartData]);

  const options = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      bottom: '8%'
    },
    legend: {
      icon: 'circle',
      top: '0',
      fontFamily: 'Manrope'
    },
    xAxis: {
      type: 'category',
      data: keys,
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      axisLabel: {
        color: '#4A4A4A',
        fontFamily: 'Manrope'
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        fontFamily: 'Manrope'
      }
    },
    series: [
      {
        name: 'Pass',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          fontFamily: 'Manrope'
        },
        data: passData,
        itemStyle: {
          borderRadius: [0, 0, 50, 50],
          label: {
            show: true,
            position: 'insideTop',
            fontSize: 12
          }
        },
        emphasis: {
          disabled: true
        }
      },
      {
        name: 'Fail',
        type: 'bar',
        stack: 'total',
        data: failData,
        itemStyle: {
          borderRadius: [50, 50, 0, 0]
        },
        emphasis: {
          disabled: true
        },
        label: {
          show: true,
          position: 'insideTop',
          fontSize: 12
        }
      }
    ],
    color: ['#B02C17', '#FBE7E3'],
    barWidth: 34
  };
  return (
    <StyledChartsPanel>
      <StyledSubHeading style={headingStyle}>{formatMessage(title)}</StyledSubHeading>
      <ReactECharts
        option={options}
        onEvents={{
          finished: handleChartFinished
        }}
        ref={chartRef}
      />
    </StyledChartsPanel>
  );
};
export default TestsByStatus;
