import { groupBy } from 'lodash';
import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { deleteConsumable } from '../../../controllers/consumable/consumable';
import { getCollection } from '../../../rxdb/collections';
import { permissions } from '../../../utils/auth';
import DataEntity from '../../generic/DataEntity';
import FormComponent from './subcomponents/DonationThresholdForm';
import ViewComponent from './subcomponents/DonationThresholdView';

const messages = defineMessages({
  title: {
    id: 'configuration.donation.threshold.title',
    defaultMessage: 'Donation thresholds'
  },
  count: {
    id: 'configuration.donation.threshold.count.label',
    defaultMessage: 'Thresholds'
  },
  searchPlaceHolder: {
    id: 'configuration.donation.threshold.search.placeholder',
    defaultMessage: 'Search'
  },
  name: {
    id: 'configuration.donation.threshold.header.name',
    defaultMessage: 'Facility name'
  },
  type: {
    id: 'configuration.donation.threshold.header.threshold',
    defaultMessage: 'Facility type'
  },

  add: {
    id: 'configuration.donation.threshold.add.button',
    defaultMessage: 'Create threshold'
  }
});

const ADD = permissions.consumable.add;
const EDIT = permissions.consumable.edit;
const DELETE = permissions.consumable.del;

/**
 * Facility Type screen
 *
 * List of available facility types.
 *
 * @component
 * @example
 * return (
 *    <Consumable />
 * );
 */
const Donationthresholdshold = () => {
  const db = useDatabase();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const subscription = getCollection(db, 'bloodProductStockLevel')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        const groupByFacility = groupBy(entries, 'facility.id');
        const docs = Object.keys(groupByFacility).map((key) => {
          const firstRow = groupByFacility[key][0];
          return {
            id: key,
            name: firstRow.facility.name,
            facility: firstRow.facility,
            type: firstRow.facility.type?.name || 'NA',
            data: groupByFacility[key]
          };
        });
        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  return (
    <MarginDiv data-testid='donation-thresholdshold'>
      <DataEntity
        crumbs={['configurationGroup', 'bloodProductStockLevel']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'type', label: messages.type }
        ]}
        addPerm={ADD}
        editPerm={EDIT}
        FormComponent={FormComponent}
        deletePerm={DELETE}
        onDelete={deleteConsumable}
        ViewComponent={ViewComponent}
        messages={messages}
      />

    </MarginDiv>
  );
};

export default Donationthresholdshold;
