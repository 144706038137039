import { groupBy } from 'lodash';
import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../containers/styled/layout';
import { useDatabase } from '../../context/database';
import { getCollection } from '../../rxdb/collections';
import { permissions } from '../../utils/auth';
import DataEntity from '../generic/DataEntity';
import FormComponent from './subcomponents/BloodProductTransferForm';
import ViewComponent from './subcomponents/BloodProductTransferView';
import ViewInventory from './ViewInventory';

const messages = defineMessages({
  title: {
    id: 'inventory.inventory.title',
    defaultMessage: 'Inventory'
  },
  count: {
    id: 'inventory.facility.count.label',
    defaultMessage: 'Facilities(s)'
  },
  searchPlaceHolder: {
    id: 'facility.type.search.placeholder',
    defaultMessage: 'Search'
  },
  name: {
    id: 'facility.name',
    defaultMessage: 'Facility'
  },
  quantity: {
    id: 'facility.quantity',
    defaultMessage: 'Quantity (Pcs)'
  },
  type: {
    id: 'facility.type.details',
    defaultMessage: 'Facility type'
  },
  add: {
    id: 'facility.type.add.button',
    defaultMessage: 'Generate report'
  }
});

// const ADD = permissions.facilityType.add;
const EDIT = permissions.facilityType.edit;

/**
 * Facility Type screen
 *
 * List of available facility types.
 *
 * @component
 * @example
 * return (
 *    <FacilityType />
 * );
 */
const BloodInventory = () => {
  const db = useDatabase();
  const [isAnotherPageOpened, setIsAnotherPageOpened] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const subscription = getCollection(db, 'bloodProduct')
      .find()
      .where({ isActive: true })
      // .sort({ name: 1 })
      .$
      .subscribe((entries) => {
        const groupByDonationCenter = groupBy(entries, 'facility.id');
        const formattedData = Object.keys(groupByDonationCenter).map((key) => {
          const firstRow = groupByDonationCenter[key][0].facility;
          return {
            id: key,
            name: firstRow.name,
            quantity: groupByDonationCenter[key].length,
            type: firstRow.type.name
          };
        });
        setData(formattedData);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  if (!isAnotherPageOpened) {
    return (
      <>
        <MarginDiv data-testid='bloodProduct-transfer'>
          <DataEntity
            crumbs={['inventoryGroup', 'inventory']}
            data={data}
            columns={[
              { dataKey: 'name', label: messages.name },
              { dataKey: 'quantity', label: messages.quantity },
              { dataKey: 'type', label: messages.type }
            ]}
            // addPerm={ADD}
            bloodTransferPerm={EDIT}
            FormComponent={FormComponent}
            // deletePerm={DELETE}
            // onDelete={deleteFacilityType}
            ViewComponent={ViewComponent}
            messages={messages}
            viewPage
            openNotherPage={(item) => {
              setSelectedData(item);
              ; setIsAnotherPageOpened(true);
            }}
          />
        </MarginDiv>
      </>
    );
  } else {
    return <ViewInventory selectedData={selectedData} goBack={() => setIsAnotherPageOpened(false)} />;
  }
};

export default BloodInventory;
