import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationNumberValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for consumable data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listConsumableTransfers(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        quantity
        dateTime

        consumable {id name}
        facility { id name address }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for facility type data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertConsumableTransfer(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationNumberValue(doc, 'quantity')}

          ${getMutationNestedValue(doc, 'consumable')}
          ${getMutationNestedValue(doc, 'facility')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize consumable data with GraphQL endpoint
 */
export const syncConsumableTransfer = (...args) => {
  return buildReplication(
    ...args,
    'consumableTransfer',
    { pullBuilder, pullQuery: 'listConsumableTransfers' },
    { pushBuilder }
  );
};
