import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationNumberValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for blood product stock level data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listBloodProductStockLevels(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode
        details

        facility { id name type {id name}}
        bloodProductType{id name}

        maximum
        minimum

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for blood product type data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertBloodProductStockLevel(
        input: {
          ${getMutationStringValue(doc, 'id')}

          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'facility')}
          ${getMutationNestedValue(doc, 'bloodProductType')}


          ${getMutationNumberValue(doc, 'maximum')}
          ${getMutationNumberValue(doc, 'minimum')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize blood product type data with GraphQL endpoint
 */
export const syncBloodProductStockLevel = (...args) => {
  return buildReplication(
    ...args,
    'bloodProductStockLevel',
    { pullBuilder, pullQuery: 'listBloodProductStockLevels' },
    { pushBuilder }
  );
};
