import { FormattedMessage } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import Modal from 'rsuite/Modal';

import { OpenModal } from '../../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../../containers/styled/layout';
import { ViewLabel, ViewLabelValue } from '../../../../containers/styled/typography';
import AuditFieldsView from '../../../generic/AuditFieldsView';

/**
 * Facility Type View screen
 *
 * Facility type view to display each type in-detail.
 *
 * @component
 * @param {object} item                Facility Type data
 * @param {boolean} handleClose        handles modal close event
 * @returns type view modal.
 *
 */
const ConsumableView = ({ item, handleClose }) => {
  return (
    <OpenModal
      data-testid='view-consumable'
      onClose={handleClose}
      size='md'
      title={
        <FormattedMessage
          id='cansumables.consumable.details.title'
          defaultMessage='Consumable Details'
        />
      }
    >
      <Modal.Body style={{ marginBottom: 30 }}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='cansumables.consumable.view.label.id'
                  defaultMessage='Consumable ID'
                />
              </ViewLabel>
              <ViewLabelValue>{item.id}</ViewLabelValue>
            </Col>
            <Col xs={6}>
              <ViewLabel>
                <FormattedMessage
                  id='cansumables.consumable.view.label.name'
                  defaultMessage='Consumable Name'
                />
              </ViewLabel>
              <ViewLabelValue>{item.name}</ViewLabelValue>
            </Col>
            <Col xs={6}>
              <ViewLabel>
                <FormattedMessage
                  id='cansumables.consumable.view.label.serialnumber'
                  defaultMessage='Serial number'
                />
              </ViewLabel>
              <ViewLabelValue>{item.serialNumber}</ViewLabelValue>
            </Col>
          </Row>
          <ViewMarginDiv />
          <Row>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='cansumables.consumable.view.label.role'
                  defaultMessage='Manufacture date'
                />
              </ViewLabel>
              <ViewLabelValue>{item.manufacturerDate ? item.manufacturerDate : '---'}</ViewLabelValue>
            </Col>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='cansumables.consumable.view.label.details'
                  defaultMessage='Expire date'
                />
              </ViewLabel>
              <ViewLabelValue>{item.expiryDate}</ViewLabelValue>
            </Col>
          </Row>
          <ViewMarginDiv />
        </Grid>
        <AuditFieldsView item={item} />
      </Modal.Body>
    </OpenModal>
  );
};

export default ConsumableView;
