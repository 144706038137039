import { groupBy } from 'lodash';
import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../containers/styled/layout';
import { useDatabase } from '../../context/database';
import { getCollection } from '../../rxdb/collections';
import DataEntity from '../generic/DataEntity';
// import FormComponent from './subcomponents/BloodProductTransferForm';
// import ViewComponent from './subcomponents/BloodProductTransferView';

const messages = defineMessages({
  title: {
    id: 'inventory.bloodProductTransfer.title',
    defaultMessage: 'Blood product transfer'
  },
  count: {
    id: 'facility.type.count.label',
    defaultMessage: 'Facilities(s)'
  },
  searchPlaceHolder: {
    id: 'facility.type.search.placeholder',
    defaultMessage: 'Search'
  },
  name: {
    id: 'facility.name',
    defaultMessage: 'Facility'
  },
  quantity: {
    id: 'facility.quantity',
    defaultMessage: 'Quantity (Pcs)'
  },
  type: {
    id: 'facility.type.details',
    defaultMessage: 'Facility type'
  },
  add: {
    id: 'inventory.add.button',
    defaultMessage: 'Generate report'
  }
});

/**
 * Facility Type screen
 *
 * List of available facility types.
 *
 * @component
 * @example
 * return (
 *    <FacilityType />
 * );
 */
const BloodProductTransfer = () => {
  const db = useDatabase();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const subscription = getCollection(db, 'bloodProduct')
      .find()
      .where({ isActive: true })
      // .sort({ name: 1 })
      .$
      .subscribe((entries) => {
        const groupByDonationCenter = groupBy(entries, 'facility.id');
        const formattedData = Object.keys(groupByDonationCenter).map((key) => {
          const firstRow = groupByDonationCenter[key][0].facility;
          return {
            id: key,
            name: firstRow.name,
            quantity: groupByDonationCenter[key].length,
            type: firstRow.type.name
          };
        });
        setData(formattedData);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  return (
    <MarginDiv data-testid='bloodProduct-transfer'>
      <DataEntity
        crumbs={['inventoryGroup', 'bloodProductTransfer']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'quantity', label: messages.quantity },
          { dataKey: 'type', label: messages.type }
        ]}
        // bloodTransferPerm={EDIT}
        // FormComponent={FormComponent}
        // ViewComponent={ViewComponent}
        messages={messages}
      />

    </MarginDiv>
  );
};

export default BloodProductTransfer;
