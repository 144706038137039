import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationArrayNestedValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for test data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listTests(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        testCode
        dueTime
        status
        details

        desktopResult
        machineResult


        testType { id name }
        laboratory { id name address }

        donor{
          id
          fullName
          donorCode
          bloodType
        }

        adverseEvent {
          id
          icode
          severity
          status

          transfusion {
            id
            icode
            dueTime
            status
            hospital { id name address }
            bloodProductType { id name shelfLife shelfLifeUnit }

            bloodProduct {
              id icode volume bloodType expiryDate
              bloodProductType { id name shelfLife shelfLifeUnit }
              donation { id donor { id donorCode } }
            }
            hospital {
              id name address
              bloodBank { id name address stockUnit { id name address } }
            }
            patient { id patientCode fullName personName gender bloodType }
          }
        }

        retentionSample {
          id icode status volume
          bloodTestingCenter { id name address }
        }

        consumables { id name }
        equipments { id name }
        healthCareWorkers { username name }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};
/**
 * Push builder for test data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertTest(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'dueTime')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationStringValue(doc, 'details')}
          ${getMutationStringValue(doc, 'desktopResult')}
          ${getMutationStringValue(doc, 'machineResult')}

          ${getMutationNestedValue(doc, 'testType')}
          ${getMutationNestedValue(doc, 'laboratory')}
          ${getMutationNestedValue(doc, 'donor')}
          ${getMutationNestedValue(doc, 'adverseEvent')}
          ${getMutationNestedValue(doc, 'retentionSample')}

          ${getMutationArrayNestedValue(doc, 'consumableIds', 'consumables')}
          ${getMutationArrayNestedValue(doc, 'equipmentIds', 'equipments')}
          ${getMutationArrayNestedValue(doc, 'healthCareWorkerNames', 'healthCareWorkers', 'username')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize test data with GraphQL endpoint
 */
export const syncTest = (...args) => {
  return buildReplication(
    ...args,
    'test',
    { pullBuilder, pullQuery: 'listTests' },
    { pushBuilder }
  );
};
