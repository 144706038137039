import PlusIcon from '@rsuite/icons/Plus';
import { groupBy } from 'lodash';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Divider, Grid, Row } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Modal from 'rsuite/Modal';
import styled from 'styled-components';

import { notify, OpenModal } from '../../../containers/styled/alerts';
import { PrimaryButton } from '../../../containers/styled/buttons';
import { TextField } from '../../../containers/styled/inputs';
import { WarningSpan } from '../../../containers/styled/layout';
import { SelectPickerField } from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import { createBloodProductTransferRecord } from '../../../controllers/inventory/bloodProductTransfer';
import { listFacility } from '../../../controllers/reports/CommonController';
import { getCollection } from '../../../rxdb/collections';
import { SelectDiv as bseeSelectedDiv } from '../../generic/styled';

const SelectDiv = styled(bseeSelectedDiv)`
  .select-md .rs-picker-toggle.rs-btn {
    height: 58px !important;
  }
`;

const StyledDiv = styled.div`
    padding: 20px;
    color: #b02c17;
    display: inline-block;
    cursor: pointer;
`;

const messages = defineMessages({
  placeholder: {
    id: 'facility.type.form.placeholder',
    defaultMessage: 'Enter Name of Facility Type'
  },

  quantityPlaceholder: {
    id: 'bloodProduct.quantity.receiving.form.placeholder',
    defaultMessage: 'Quantity'
  },

  rolePlaceholder: {
    id: 'users.role.form.placeholder',
    defaultMessage: 'Select Role'
  },

  facilityPlaceholder: {
    id: 'facility.receiving.placeholder',
    defaultMessage: 'Select Facility'
  },

  bloodProductTypePlaceholder: {
    id: 'bloodProductType.receiving.placeholder',
    defaultMessage: 'Select blood product type'
  },

  detailsPlaceholder: {
    id: 'facility.type.details.form.placeholder',
    defaultMessage: 'Enter Details'
  },

  transferTitle: {
    id: 'bloodProduct.transfer.title',
    defaultMessage: 'Blood product transfer'
  },

  // CREATE
  createTitle: {
    id: 'facility.type.create.title',
    defaultMessage: 'Blood product transfer'
  },

  createHeader: {
    id: 'facility.type.form.create.header',
    defaultMessage: 'Created'
  },

  createSuccess: {
    id: 'facility.type.form.create.success',
    defaultMessage: 'Facility Type Created Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'facility.type.form.update.title',
    defaultMessage: 'Edit Facility Type'
  },

  updateHeader: {
    id: 'facility.type.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'facility.type.form.update.success',
    defaultMessage: 'Facility Type Updated Successfully'
  },

  failed: {
    id: 'facility.type.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'facility.type.form.save.failed',
    defaultMessage: 'Could not save Facility Type'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'facility.type.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'facility.type.form.name.error.exists',
    defaultMessage: 'Facility Type already exists'
  },
  nameMaxLengthError: {
    id: 'facility.type.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

const submitEnable = (data) => {
  if (!data.quantity || data.quantity === 0) return false;
  else if (!data.facility) return false;
  else if (!data.bloodProductType) return false;
  return true;
};

/**
 * Blood product transfer Form screen
 *
 * Blood product transfer form to create / update categories.
 *
 * @component
 * @param {object} item                prop used to pre-populate the form fields with data when editing
 * @param {boolean} handleClose        handles modal close event
 * @returns type form modal.
 *
 */
const BloodProductTransferForm = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();
  const [numberOfRows, setNumberRows] = React.useState(1);

  const db = useDatabase();
  const [isActive, setIsActive] = React.useState(false);
  const [data, setData] = React.useState({
    pickup: null,
    facility: null,
    bloodProductType: null,
    quantity: 0
  });

  const [facility, setFacility] = React.useState(null);

  const [facilityData, setFacilityData] = React.useState([]);
  const [bloodProductTypes, setBloodProductTypes] = React.useState([]);
  const initialData = Array.from({ length: numberOfRows }, () => ({
    bloodProductType: { id: '', name: '' },
    quantity: ''
  }));

  const [rowsData, setRowsData] = React.useState(initialData);

  const addMoreRows = () => {
    setNumberRows((pre) => pre + 1);
  };

  const handleDataChange = (index, field, value) => {
    const newRowsData = [...rowsData];
    if (field === 'bloodProductType') {
      const fData = bloodProductTypes.find((bp) => bp.value === value);
      newRowsData[index] = {
        ...newRowsData[index],
        bloodProductType: { id: value, name: fData.label }
      };
    } else {
      newRowsData[index] = { ...newRowsData[index], [field]: value };
    }
    setRowsData(newRowsData);
  };

  const clearData = (index) => {
    const newRowsData = [...rowsData];
    newRowsData[index] = { bloodProductType: { id: '', name: '' }, quantity: '' };
    setRowsData(newRowsData);
  };

  const removeRow = (index) => {
    const newRowsData = rowsData.filter((_, i) => i !== index);
    setRowsData(newRowsData);
  };

  const submit = async (event) => {
    event.stopPropagation();

    const action = createBloodProductTransferRecord;
    const header = formatMessage(messages.createHeader);
    const successMessage = formatMessage(messages.createSuccess);
    const failureMessage = formatMessage(messages.saveFailed);

    try {
      // Create an array of promises for each save action
      const savePromises = rowsData.map((row) =>
        action(db, { ...row, destination: facility, pickup: item })
      );

      // Wait for all save operations to complete
      const results = await Promise.all(savePromises);

      // Check if all operations were successful
      const allSuccessful = results.every((result) => result);

      // Show notification based on the success or failure of all operations
      notify(
        allSuccessful ? successMessage : failureMessage,
        header,
        allSuccessful ? 'success' : 'error'
      );

      // Handle close action
      handleClose();
    } catch (error) {
      // Handle any errors that occurred during the save operations
      notify(failureMessage, header, 'error');
      handleClose();
    }
  };

  React.useEffect(() => {
    const entry = item || {};
    setData((pre) => ({
      ...pre,
      pickup: { id: entry.id, name: entry.name }
    }));
  }, [item]);

  React.useEffect(() => {
    listFacility(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive && entry.id !== item.id) // filter out inactive entries
        .map((entry) => ({
          value: entry.id,
          label: entry.name
        }));
      setFacilityData(docs);
    });
  }, [db, item]);

  React.useEffect(() => {
    if (!item) return;
    const subscription = getCollection(db, 'bloodProduct')
      .find()
      .where({ isActive: true, 'facility.id': item.id })
      .$
      .subscribe((entries) => {
        const groupByDonationCenter = groupBy(entries, 'bloodProductType.id');
        const formattedData = Object.keys(groupByDonationCenter).map((key) => {
          const firstRow = groupByDonationCenter[key][0].bloodProductType;
          return {
            value: key,
            label: firstRow.name
          };
        });
        setBloodProductTypes(formattedData);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, item]);

  React.useEffect(() => {
    setIsActive(submitEnable());
  }, [data]);

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(messages.transferTitle)}
    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='facility.receiving.title'
                  defaultMessage='receiving facility'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={facility ? facility.id : ''}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.facilityPlaceholder)}
                  data={facilityData}
                  onChange={(value) => {
                    const fData = facilityData.find((f) => f.value === value);
                    setFacility({ id: value, name: fData.label });
                  }}
                />
              </SelectDiv>
              <Divider />
            </Col>

            {Array.from({ length: numberOfRows }).map((_, i) => (
              <Col xs={24} key={i}>
                <table className='inner-table' style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '50%', textAlign: 'left' }}>
                        <Label>
                          <FormattedMessage
                            id='receiving.bloodProduct.title'
                            defaultMessage='Blood product type'
                          />
                        </Label>
                      </th>
                      <th style={{ width: '20%', textAlign: 'left' }}>
                        <Label>
                          <FormattedMessage
                            id='bloodProduct.form.label.quantity'
                            defaultMessage='Quantity'
                          />
                          <WarningSpan>*</WarningSpan>
                        </Label>
                      </th>
                      <th style={{ width: '10%' }} />
                      <th style={{ width: '10%', textAlign: 'left' }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <SelectDiv>
                          <SelectPickerField
                            style={{ width: '70%' }}
                            value={rowsData[i]?.bloodProductType.id}
                            searchable={false}
                            className='select-sm'
                            placeholder={formatMessage(messages.bloodProductTypePlaceholder)}
                            data={bloodProductTypes}
                            onChange={(value) => handleDataChange(i, 'bloodProductType', value)}
                          />
                        </SelectDiv>
                      </td>
                      <td>
                        <TextField
                          style={{ width: '76px' }}
                          value={rowsData[i]?.quantity}
                          onChange={(value) => handleDataChange(i, 'quantity', value)}
                          placeholder={formatMessage(messages.quantityPlaceholder)}
                        />
                      </td>
                      <td />
                      <td style={{ cursor: 'pointer', width: '20%' }}>
                        <span onClick={() => clearData(i)}>Clear</span> |
                        <span onClick={() => removeRow(i)}>Remove</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            ))}

            <StyledDiv onClick={addMoreRows}>
              <PlusIcon /> <span>Add Blood Products</span>
            </StyledDiv>
          </Row>

        </Grid>
      </Modal.Body>

      <Modal.Footer>
        <FlexboxGrid justify='end'>
          <FlexboxGrid.Item colspan={12}>
            <PrimaryButton disabled={!isActive} onClick={submit} style={{ width: '100%' }}>
              <FormattedMessage
                id='facility.type.form.submit'
                defaultMessage='Submit'
              />
            </PrimaryButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </OpenModal>
  );
};

export default BloodProductTransferForm;
