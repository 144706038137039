import { auditFields, emptyMigration, operations, types } from '../common';

/**
 * Entity schema
 */
const schema = {
  version: 1,

  title: 'Consumable',
  description: 'Consumable used in any blood donation action',

  primaryKey: 'id',
  type: 'object',
  properties: {
    id: types.ID,
    icode: types.STRING_INDEX,

    name: types.STRING_INDEX,
    serialNumber: types.STRING_INDEX,
    manufacturerDate: types.DATE_INDEX,
    expiryDate: types.DATE,

    facility: types.OBJECT,
    quantity: types.NUMBER,

    // Audit fields
    ...auditFields
  },

  required: ['id', 'serialNumber'],
  indexes: [
    ['name'],
    ['serialNumber']
  ],
  keyCompression: true
};

/**
 * Migration strategies
 */
const migrationStrategies = {
  // removed "item" field and added "qunatity" field
  1: emptyMigration
};

/**
 * Collection methods
 */
const statics = {
  /**
   * Creates new instance
   *
   * Also updates full and person names
   *
   * @param {object} data  New instance
   * @returns Promise
   */
  add: function (data) {
    return operations.add(this, data);
  },

  /**
   * Updates instance
   *
   * Also updates full and person names
   *
   * @param {object} data  Instance
   * @returns Promise
   */
  edit: function (data) {
    return operations.edit(this, data);
  },

  /**
   * Deletes instance
   *
   * @param {string} id  Instance ID
   * @returns Promise
   */
  del: function (id) {
    return operations.del(this, id);
  }
};

/**
 * Instance methods
 */
const methods = {};

/**
 * Collection definition
 */
const collection = { schema, migrationStrategies, statics, methods };

export default collection;
