import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Modal from 'rsuite/Modal';

import { notify, OpenModal } from '../../../containers/styled/alerts';
import { PrimaryButton } from '../../../containers/styled/buttons';
import { TextField } from '../../../containers/styled/inputs';
import { WarningSpan } from '../../../containers/styled/layout';
import { Label } from '../../../containers/styled/typography';
import { useAuth } from '../../../context/auth';
import { useDatabase } from '../../../context/database';
import { createConsumable, updateConsumable } from '../../../controllers/consumable/consumable';

const messages = defineMessages({
  placeholder: {
    id: 'consumables.consumable.form.placeholder',
    defaultMessage: 'Enter Name of Consumables'
  },

  serialNumberPlaceholder: {
    id: 'consumables.serialNumber.form.placeholder',
    defaultMessage: 'Serial number'
  },

  quantityPlaceholder: {
    id: 'consumables.quantity.form.placeholder',
    defaultMessage: 'Quantity'
  },

  consumablePlaceholder: {
    id: 'consumables.consumable.placeholder',
    defaultMessage: 'Select consumable'
  },

  consumableFormTitle: {
    id: 'consumable.form.title',
    defaultMessage: 'Create consumable'
  },

  // CREATE
  createTitle: {
    id: 'consumables.consumable.create.title',
    defaultMessage: 'Create consumable'
  },

  createHeader: {
    id: 'consumables.consumable.form.create.header',
    defaultMessage: 'Created'
  },

  createSuccess: {
    id: 'consumables.consumable.form.create.success',
    defaultMessage: 'Consumables Created Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'consumables.consumable.form.update.title',
    defaultMessage: 'Edit Consumable'
  },

  updateHeader: {
    id: 'consumables.consumable.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'consumables.consumable.form.update.success',
    defaultMessage: 'Consumable Updated Successfully'
  },

  failed: {
    id: 'consumables.consumable.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'consumables.consumable.form.save.failed',
    defaultMessage: 'Could not save Facility Type'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'consumables.consumable.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'consumables.consumable.form.name.error.exists',
    defaultMessage: 'Facility Type already exists'
  },
  nameMaxLengthError: {
    id: 'consumables.consumable.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

/**
 * Consumable Form screen
 *
 * Consumable form to create / update categories.
 *
 * @component
 * @param {object} item                prop used to pre-populate the form fields with data when editing
 * @param {boolean} handleClose        handles modal close event
 * @returns type form modal.
 *
 */
const ConsumableForm = ({ item, handleClose }) => {
  const { auth } = useAuth();
  const { formatMessage } = useIntl();
  const db = useDatabase();

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [facility, setFacility] = React.useState(null);
  const [data, setData] = React.useState({
    id: '',
    manufacturerDate: null,
    expiryDate: null,
    facility: null,
    serialNumber: '',
    name: '',
    quantity: 1
  });

  const submit = (event) => {
    event.stopPropagation();

    let action;
    let header;
    let message;

    if (!data.id) {
      action = createConsumable;
      header = formatMessage(messages.createHeader);
      message = formatMessage(messages.createSuccess);
    } else {
      action = updateConsumable;
      header = formatMessage(messages.updateHeader);
      message = formatMessage(messages.updateSuccess);
    }

    action(db, data).then((done) => {
      notify(
        done ? message : formatMessage(messages.saveFailed),
        header,
        done ? 'success' : 'error'
      );
      handleClose();
    });
  };

  const isAnyFieldEmpty = React.useCallback(() => {
    // Check top-level fields
    if (!data.serialNumber || !data.manufacturerDate || !data.expiryDate) {
      return true;
    }
    return false;
  }, [data]);

  React.useEffect(() => {
    if (!auth) return;
    if (!auth.user) return;

    setData({ ...data, facility: auth.user.facility });
    setFacility(auth.user.facility);
  }, [auth, data]);

  React.useEffect(() => {
    if (!item) return;
    const entry = item || {};
    setData({
      id: entry.id,
      name: entry.name || '',
      manufacturerDate: entry.manufacturerDate || '',
      facility,
      expiryDate: entry.expiryDate,
      serialNumber: entry.serialNumber || '',
      quantity: entry.quantity
    });
  }, [item, facility]);

  React.useEffect(() => {
    const isSubmitEnable = isAnyFieldEmpty();
    setIsDisabled(isSubmitEnable);
  }, [data, isAnyFieldEmpty]);

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(!data.id ? messages.createTitle : messages.updateTitle)}

    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.name.title'
                  defaultMessage='Consumable name'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.name}
                onChange={(value) => {
                  setData({ ...data, name: value });
                }}
                placeholder={formatMessage(messages.consumablePlaceholder)}
              />
            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.serealNo.title'
                  defaultMessage='Serial number'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.serialNumber}
                onChange={(value) => {
                  setData({ ...data, serialNumber: value });
                }}
                placeholder={formatMessage(messages.serialNumberPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.manufactureDate.title'
                  defaultMessage='Manufacture date'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.manufacturerDate}
                type='date'
                onChange={(value) => {
                  setData({ ...data, manufacturerDate: value });
                }}
                placeholder={formatMessage(messages.quantityPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.expiryDate.title'
                  defaultMessage='Expiry date'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.expiryDate}
                type='date'
                onChange={(value) => {
                  setData({ ...data, expiryDate: value });
                }}
                placeholder={formatMessage(messages.quantityPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.quantity.title'
                  defaultMessage='Quantity'
                />
                {/* <WarningSpan>*</WarningSpan> */}
              </Label>

              <TextField
                value={data.quantity}
                onChange={(value) => {
                  setData({ ...data, quantity: value });
                }}
                placeholder={formatMessage(messages.quantityPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <FlexboxGrid justify='end' style={{ marginTop: '50px' }}>
                <FlexboxGrid.Item colspan={24}>
                  <PrimaryButton onClick={submit} style={{ width: '100%' }} disabled={isDisabled}>
                    <FormattedMessage
                      id='consumables.consumable.form.submit'
                      defaultMessage='Submit'
                    />
                  </PrimaryButton>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>

          </Row>

        </Grid>
      </Modal.Body>
    </OpenModal>
  );
};

export default ConsumableForm;
