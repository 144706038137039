import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { statusMsg, translations } from '../../../translations/en/common';
import { parseDate } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'bloodTest.title',
    defaultMessage: 'Blood Tests'
  },
  count: {
    id: 'bloodTest.count.label',
    defaultMessage: 'Blood Test(s)'
  },
  searchPlaceHolder: {
    id: 'bloodTest.search.placeholder',
    defaultMessage: 'Search Testing Records'
  },
  donorCode: {
    id: 'bloodTest.donor.code',
    defaultMessage: 'Donor Code'
  },
  testType: {
    id: 'bloodTest.test.type',
    defaultMessage: 'Test Type'
  },
  facility: {
    id: 'bloodTest.facility',
    defaultMessage: 'Facility'
  },
  result: {
    id: 'bloodTest.result',
    defaultMessage: 'Result'
  },
  date: {
    id: 'bloodTest.date',
    defaultMessage: 'Date'
  }
});

/**
 * Blood-test screen
 *
 * Blood-test to display data table showing blood-tests.
 *
 * @component
 * @example
 * return (
 *    <BloodTests />
 * );
 *
 */
const BloodTests = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    testType: '',
    result: '',
    status: '',
    datePeriod: []
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'test')
      .find()
      .where({ isActive: true })
      .sort({ dueTime: 'asc' })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          name: entry.donor
            ? entry.donor.donorCode
            : formatMessage(translations.notApplicable),
          testType: entry.testType
            ? entry.testType.name
            : formatMessage(translations.notApplicable),
          facility: entry.laboratory
            ? entry.laboratory.name
            : formatMessage(translations.notApplicable),
          date: entry.dueTime
            ? parseDate(entry.dueTime)
            : formatMessage(translations.notApplicable),
          result: entry.desktopResult === 'negative' && entry.machineResult === 'negative'
            ? 'Pass'
            : 'Fail',
          status: entry.status
            ? formatMessage(statusMsg[entry.status])
            : formatMessage(translations.notApplicable),
          healthCareWorkers:
            entry.healthCareWorkers.length > 0
              ? entry.healthCareWorkers.map((data) => data.name)
              : formatMessage(translations.notApplicable),
          equipments:
            entry.equipments.length > 0
              ? entry.equipments
              : formatMessage(translations.notApplicable),
          consumables:
            entry.consumables.length > 0
              ? entry.consumables
              : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'testing']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.donorCode, flex: 2 },
          { dataKey: 'testType', label: messages.testType, flex: 1.5 },
          { dataKey: 'facility', label: messages.facility, flex: 2 },
          { dataKey: 'result', label: messages.result },
          { dataKey: 'date', label: messages.date, flex: 1.5 }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
      />
    </MarginDiv>
  );
};
export default BloodTests;
