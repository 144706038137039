import { v4 as uuid4 } from 'uuid';

import { getCollection } from '../../rxdb/collections';

/**
 * List All Consumable Item (also disabled)
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const listConsumableItems = async (db) => {
  return getCollection(db, 'item')
    .find()
    .exec()
    .then((documents) => {
      return documents.map((entry) => ({
        id: entry.id,
        name: entry.name,
        isActive: entry.isActive
      }));
    });
};

/**
 * Creates Consumable Record
 *
 * @param {*} db    RxDB database
 * @param {*} data  consumable data
 * @returns Promise indicating if action succeed
 */
export const createDonationThreshold = (db, data) => {
  const doc = {
    id: uuid4(),
    facility: data.facility,
    bloodProductType: data.bloodProductType,
    maximum: data.maximum,
    minimum: data.minimum,

    isActive: true,
    // Audit fields
    lastmodifiedAt: new Date().toISOString()
  };

  return getCollection(db, 'bloodProductStockLevel')
    .insert(doc)
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Updates User Role
 *
 * @param {*} db    RxDB database
 * @param {*} data  user role updated data (along with the id)
 * @returns Promise indicating if action succeed
 */
export const updateConsumable = (db, data) => {
  const { id, ...updated } = data;
  const doc = getCollection(db, 'consumable').findOne({ selector: { id } });

  return doc
    .update({
      $set: {
        ...updated,
        // Audit fields
        lastmodifiedAt: new Date().toISOString()
      }
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Deletes User Role
 *
 * @param {*} db    RxDB database
 * @param {*} data  user role data
 * @returns Promise indicating if action succeed
 */
export const deleteConsumable = (db, data) => {
  return updateConsumable(db, {
    id: data.id,
    // workaround to skip name uniqueness constraint
    name: `${data.name} :: ${data.id} :: ${new Date().toISOString()}`,
    isActive: false
  });
};
