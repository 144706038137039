import React from 'react';
import { useIntl } from 'react-intl';

import { FaIcon, MarginDiv, MarginSpan } from '../../containers/styled/layout';
import { routeMessages } from './messages';
import { routes } from './routes';

/**
 * Component that renders the route breadcrumb.
 *
 * @component
 * @example
 * return (
 *    <BreadCrumb crumbs={['routeGroup', 'routeChild']} />
 * );
 */
const BreadCrumb = ({ crumbs = [] }) => {
  const { formatMessage } = useIntl();

  return (
    <MarginDiv data-testid='breadcrumb' style={{ fontSize: '14px' }}>
      <span data-testid='breadcrumb-home' href={routes.home}>
        <FaIcon icon='house' />
      </span>

      {crumbs
        .filter((crumb) => routeMessages[crumb])
        .map((crumb) => (
          <span key={crumb} data-testid={`breadcrumb-${crumb}`}>
            <MarginSpan> / </MarginSpan>
            <span>
              {formatMessage(routeMessages[crumb])}
            </span>
          </span>
        ))}
    </MarginDiv>
  );
};

export default BreadCrumb;
