import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Modal from 'rsuite/Modal';
import styled from 'styled-components';

import { notify, OpenModal } from '../../../../containers/styled/alerts';
import { PrimaryButton } from '../../../../containers/styled/buttons';
import { TextField } from '../../../../containers/styled/inputs';
import { WarningSpan } from '../../../../containers/styled/layout';
import { SelectPickerField } from '../../../../containers/styled/styled';
import { Label } from '../../../../containers/styled/typography';
import { useDatabase } from '../../../../context/database';
import { createConsumable, listConsumableItems, updateConsumable } from '../../../../controllers/consumable/consumable';
import { SelectDiv as bseeSelectedDiv } from '../../../generic/styled';

const SelectDiv = styled(bseeSelectedDiv)`
  .select-md .rs-picker-toggle.rs-btn {
    height: 58px !important;
  }
`;

const messages = defineMessages({
  placeholder: {
    id: 'consumables.consumable.form.placeholder',
    defaultMessage: 'Enter Name of Consumables'
  },

  serialNumberPlaceholder: {
    id: 'consumables.serialNumber.form.placeholder',
    defaultMessage: 'Serial number'
  },

  quantityPlaceholder: {
    id: 'consumables.quantity.form.placeholder',
    defaultMessage: 'Quantity'
  },

  cansumablePlaceholder: {
    id: 'consumables.consumable.placeholder',
    defaultMessage: 'Select consumable'
  },

  consumableFormTitle: {
    id: 'consumable.form.title',
    defaultMessage: 'Create consumable'
  },

  // CREATE
  createTitle: {
    id: 'consumables.consumable.create.title',
    defaultMessage: 'Create consumable'
  },

  createHeader: {
    id: 'consumables.consumable.form.create.header',
    defaultMessage: 'Created'
  },

  createSuccess: {
    id: 'consumables.consumable.form.create.success',
    defaultMessage: 'Consumables Created Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'consumables.consumable.form.update.title',
    defaultMessage: 'Edit Consumable'
  },

  updateHeader: {
    id: 'consumables.consumable.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'consumables.consumable.form.update.success',
    defaultMessage: 'Consumable Updated Successfully'
  },

  failed: {
    id: 'consumables.consumable.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'consumables.consumable.form.save.failed',
    defaultMessage: 'Could not save Facility Type'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'consumables.consumable.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'consumables.consumable.form.name.error.exists',
    defaultMessage: 'Facility Type already exists'
  },
  nameMaxLengthError: {
    id: 'consumables.consumable.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

/**
 * Consumable Form screen
 *
 * Consumable form to create / update categories.
 *
 * @component
 * @param {object} item                prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose        handles modal close event
 * @returns type form modal.
 *
 */
const ConsumableForm = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();

  const [data, setData] = React.useState({
    id: '',
    manufacturerDate: null,
    expiryDate: null,
    serialNumber: '',
    item: {
      id: '',
      name: ''
    },
    name: ''
  });

  const [consumableItemData, setConsumableItemData] = React.useState([]);
  const [volume, setVolume] = React.useState(0);
  const [isDisbaled, setIsDisabled] = React.useState(true);

  const submit = (event) => {
    event.stopPropagation();

    let action;
    let header;
    let message;

    if (!data.id) {
      action = createConsumable;
      header = formatMessage(messages.createHeader);
      message = formatMessage(messages.createSuccess);
    } else {
      action = updateConsumable;
      header = formatMessage(messages.updateHeader);
      message = formatMessage(messages.updateSuccess);
    }

    action(db, data).then((done) => {
      notify(
        done ? message : formatMessage(messages.saveFailed),
        header,
        done ? 'success' : 'error'
      );
      handleClose();
    });
  };

  React.useEffect(() => {
    if (!item) return;
    const entry = item || {};
    setData({
      id: entry.id,
      name: entry.name || '',
      manufacturerDate: entry.manufacturerDate || '',
      expiryDate: entry.expiryDate,
      item: {
        id: entry.item.id,
        name: entry.item.name
      },
      serialNumber: entry.serialNumber || ''
    });
  }, [item]);

  React.useEffect(() => {
    // getting consumable item list
    listConsumableItems(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({ value: entry.id, label: entry.name }));
      setConsumableItemData(docs);
    });
  }, [db]);

  const isAnyFieldEmpty = React.useCallback(() => {
    // Check top-level fields
    if (!data.serialNumber || !data.manufacturerDate || !data.expiryDate) {
      return true;
    }

    // Check nested fields in item
    if (!data.item.id) {
      return true;
    }

    return false;
  }, [data]);

  React.useEffect(() => {
    const isSubmitEnable = isAnyFieldEmpty();
    setIsDisabled(isSubmitEnable);
  }, [data, isAnyFieldEmpty]);

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(!data.id ? messages.createTitle : messages.updateTitle)}

    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.name.title'
                  defaultMessage='Consumable name'
                />
                <WarningSpan>*</WarningSpan>
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={data.item.id || ''}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.cansumablePlaceholder)}
                  data={consumableItemData}
                  onChange={(value) => {
                    const consuTtem = consumableItemData.find(item => item.value === value);
                    setData({ ...data, item: { name: consuTtem.label, id: value } });
                  }}
                />
              </SelectDiv>

            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.serealNo.title'
                  defaultMessage='Serial number'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.serialNumber}
                onChange={(value) => {
                  setData({ ...data, serialNumber: value });
                }}
                placeholder={formatMessage(messages.serialNumberPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.manufactureDate.title'
                  defaultMessage='Manufacture date'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.manufacturerDate}
                type='date'
                onChange={(value) => {
                  setData({ ...data, manufacturerDate: value });
                }}
                placeholder={formatMessage(messages.quantityPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.expiryDate.title'
                  defaultMessage='Expiry date'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.expiryDate}
                type='date'
                onChange={(value) => {
                  setData({ ...data, expiryDate: value });
                }}
                placeholder={formatMessage(messages.quantityPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='consumables.consumable.quantity.title'
                  defaultMessage='Quantity'
                />
                {/* <WarningSpan>*</WarningSpan> */}
              </Label>

              <TextField
                value={volume}
                onChange={(value) => {
                  setVolume(value);
                }}
                placeholder={formatMessage(messages.quantityPlaceholder)}
              />
            </Col>

            <Col md={12}>
              <FlexboxGrid justify='end' style={{ marginTop: '50px' }}>
                <FlexboxGrid.Item colspan={24}>
                  <PrimaryButton onClick={submit} style={{ width: '100%' }} disabled={isDisbaled}>
                    <FormattedMessage
                      id='consumables.consumable.form.submit'
                      defaultMessage='Submit'
                    />
                  </PrimaryButton>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>

          </Row>

        </Grid>
      </Modal.Body>
    </OpenModal>
  );
};

export default ConsumableForm;
