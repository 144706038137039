import CollapseIcon from '@rsuite/icons/CollaspedOutline';
import ExpandIcon from '@rsuite/icons/ExpandOutline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconButton, Table } from 'rsuite';

import { HeaderSpan, WhiteDiv } from '../../containers/styled/layout';
import SimpleDataTable from './SimpleDataTable';
import {
  activeState,
  cancelledState,
  Chips,
  completedState,
  disabledState,
  otherState,
  upcomingState
} from './styled';

const rowKey = 'id';

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
  <Table.Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance='subtle'
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        expandedRowKeys.some(key => key === rowData[rowKey]) ? <CollapseIcon /> : <ExpandIcon />
      }
    />
  </Table.Cell>
);

const renderRowExpanded = rowData => {
  return (
    <div>
      <SimpleDataTable
        data={rowData}
        column={rowData.childrenColumn}
        isStatus
      />
    </div>
  );
};

const ExpandableTable = ({
  tData = [],
  isStatus
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach(key => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const getStyle = (status) => {
    if (['active', 'discharged'].includes(status)) return activeState;
    if (['admitted', 'upcoming'].includes(status)) return upcomingState;
    if (['in-progress', 'scheduled'].includes(status)) return disabledState;
    if (['completed', 'ongoing'].includes(status)) return completedState;
    if (['cancelled'].includes(status)) return cancelledState;
    return otherState;
  };

  const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props}>
        <Chips style={getStyle(rowData.status.toLowerCase())}>
          {rowData.status}
        </Chips>
      </Table.Cell>
    );
  };

  return (
    <WhiteDiv>
      <Table
                // Prevent the scrollbar from scrolling
                // to the top after the table content area height changes.
        shouldUpdateScroll
        height={600}
        data={tData}
        rowKey={rowKey}
        expandedRowKeys={expandedRowKeys}
        onRowClick={data => { console.log(data); }}
        renderRowExpanded={renderRowExpanded}
      >
        <Table.Column align='left' flexGrow={1}>
          <Table.HeaderCell>
            <HeaderSpan>
              <FormattedMessage
                id='table.header.blood-product-type'
                defaultMessage='BLOOD PRODUCT TYPE'
              />
            </HeaderSpan>
          </Table.HeaderCell>
          <Table.Cell dataKey='name'>
            {rowData => `${rowData.name}`}
          </Table.Cell>
        </Table.Column>

        <Table.Column align='left' flexGrow={1}>
          <Table.HeaderCell>
            <HeaderSpan>
              <FormattedMessage
                id='table.header.quantity'
                defaultMessage='QUANTITY'
              />
            </HeaderSpan>
          </Table.HeaderCell>
          <Table.Cell dataKey='quantity'>
            {rowData => rowData.quantity}
          </Table.Cell>
        </Table.Column>

        {isStatus && (
          <Table.Column align='left' width={105}>
            <Table.HeaderCell style={{ padding: '10px' }}>
              <HeaderSpan>
                <FormattedMessage
                  id='table.header.status'
                  defaultMessage='STATUS'
                />
              </HeaderSpan>
            </Table.HeaderCell>

            <StatusCell dataKey='id' />
          </Table.Column>
        )}

        <Table.Column align='center' flexGrow={1}>
          <Table.HeaderCell>
            <HeaderSpan>
              <FormattedMessage
                id='table.header.action'
                defaultMessage='ACTION'
              />
            </HeaderSpan>
          </Table.HeaderCell>
          <ExpandCell
            dataKey='id'
            expandedRowKeys={expandedRowKeys}
            onChange={handleExpanded}
          />
        </Table.Column>
      </Table>
    </WhiteDiv>
  );
};

export default ExpandableTable;
