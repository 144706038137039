import { jsPDF as JsPDF } from 'jspdf';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CheckboxGroup, Col, Dropdown, FlexboxGrid, Grid, Row } from 'rsuite';
import Modal from 'rsuite/Modal';

import exportIcon from '../../assets/svg-images/export.svg';
import filterIcon from '../../assets/svg-images/filter-dash.svg';
import { OpenModal } from '../../containers/styled/alerts';
import { PrimaryButton, PrimaryHoloButton } from '../../containers/styled/buttons';
import { StyledCheckbox } from '../../containers/styled/checkbox';
import { MarginDiv } from '../../containers/styled/layout';
import { StyledDivider, StyledPanel } from '../../containers/styled/styled';
import { H2, H3, H5, Hr } from '../../containers/styled/typography';
import { useDatabase } from '../../context/database';
import { getCollection } from '../../rxdb/collections';
import { getCurrentMonthData } from '../../utils';
import { DropdownButton, DropdownList } from '../generic/styled';
import BreadCrumb from '../routing/BreadCrumb';
import BloodDonations from './charts/blood-donations';
import DonorsByAge from './charts/donors-by-age';
import DonorsByBloodGroup from './charts/donors-by-blood-group';
import DonorsByGender from './charts/donors-by-gender';
import TestsType from './charts/test-type';
import TestsByStatus from './charts/tests-by-status';
import { StyledSubHeading } from './styled';

const Dashboard = () => {
  const [showFilter, setShowFilter] = useState(false);
  const { formatMessage } = useIntl();
  const messages = defineMessages({
    title: {
      id: 'dashboard.title',
      defaultMessage: 'Dashboard'
    },
    filterContext: {
      id: 'dashboard.filter',
      defaultMessage: 'Stats for this Month'
    },
    export: {
      id: 'dashboard.export',
      defaultMessage: 'Export'
    },
    filter: {
      id: 'dashboard.filter',
      defaultMessage: 'Filter'
    },
    registeredDonors: {
      id: 'dashboard.donors',
      defaultMessage: 'Total No. of Registered Donors'
    },
    donations: {
      id: 'dashboard.donations',
      defaultMessage: 'Total No. of Donations'
    },
    bloodProducts: {
      id: 'dashboard.bloodProducts',
      defaultMessage: 'Total No. of Blood Products'
    },
    requests: {
      id: 'dashboard.requests',
      defaultMessage: 'Blood Products Requests'
    },
    appointments: {
      id: 'dashboard.appointments',
      defaultMessage: 'Total No. of Appointments'
    },
    donorsByGender: {
      id: 'dashboard.donorsByGender',
      defaultMessage: 'Proportion of Donors by Gender'
    },
    noOfdonations: {
      id: 'dashboard.noOfdonations',
      defaultMessage: 'Number of Blood Donation this Month'
    },
    donorByBloodGroup: {
      id: 'dashboard.donorByBloodGroup',
      defaultMessage: 'Distribution of Donors by Blood Group'
    },
    donorByAge: {
      id: 'dashboard.donorByAge',
      defaultMessage: 'Distribution of Donors by Age'
    },
    testsByStatus: {
      id: 'dashboard.chart.testsByStatus',
      defaultMessage: 'Distribution of Tests by Status'
    },
    testsType: {
      id: 'dashboard.chart.testsType',
      defaultMessage: 'Distribution by Test Type'
    },
    type: {
      id: 'dashboard.filter.type',
      defaultMessage: 'type'
    }
  });

  const commonStyles = {
    display: 'flex',
    alignItems: 'center'
  };

  const btnStyle = {
    padding: '0.65rem 1.25rem',
    ...commonStyles
  };

  const rowStyle = {
    marginLeft: -16,
    marginRight: -16
  };

  const checkData = [
    formatMessage(messages.registeredDonors),
    formatMessage(messages.donations),
    formatMessage(messages.bloodProducts),
    formatMessage(messages.requests),
    formatMessage(messages.appointments),
    formatMessage(messages.donorsByGender),
    formatMessage(messages.noOfdonations),
    formatMessage(messages.donorByBloodGroup),
    formatMessage(messages.donorByAge),
    formatMessage(messages.testsByStatus),
    formatMessage(messages.testsType)
  ];
  const [value, setValue] = React.useState(checkData);
  const [filteredValue, setFilteredValue] = React.useState(checkData);
  const [selectedColFilters, setSelectedColFilters] = useState(checkData);
  const handleCheckAll = (value, checked) => setValue(checked ? checkData : []);
  const handleChange = value => setValue(value);
  const [donorsCount, setDonorsCount] = useState(0);
  const [donationsCount, setDonationsCount] = useState(0);
  const [bloodProductCount, setBloodProduct] = useState(0);
  const [bloodProductReqCount, setBloodProductReqCount] = useState(0);
  const [appointmentsCount, setAppointmentsCount] = useState(0);
  const [donorsData, setDonorsData] = useState([]);
  const [bloodGroupData, setBloodGroupData] = useState({});
  const [donorsAgeData, setDonorsAgeData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [bloodGroupKeys, setBloodGroupKeys] = useState([]);
  const [bloodGroupValues, setBloodGroupValues] = useState([]);
  const [donorsAgeKeys, setDonorsAgeKeys] = useState([]);
  const [donorsAgeValues, setDonorsAgeValues] = useState([]);
  const [statusKeys, setStatusKeys] = useState([]);
  const [statusValues, setStatusValues] = useState([]);
  const [testTypeData, setTestTypeData] = useState({});
  const [testTypeKeys, setTestTypeKeys] = useState([]);
  const [testTypeValues, setTestTypeValues] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [donationData, setDonationData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState('');
  const db = useDatabase();
  let genderImage;
  let donationImage;
  let bloodGroupImage;
  let donorsAgeImage;
  let statusImage;
  let testTypeImage;

  useEffect(() => {
    setCurrentMonth(new Date(Date.now()).toLocaleString('en-US', { month: 'long' }));
  }, []);

  const setImage = (data, type) => {
    switch (type) {
      case formatMessage(messages.donorsByGender):
        genderImage = data;
        break;
      case formatMessage(messages.noOfdonations):
        donationImage = data;
        break;
      case formatMessage(messages.donorByBloodGroup):
        bloodGroupImage = data;
        break;
      case formatMessage(messages.donorByAge):
        donorsAgeImage = data;
        break;
      case formatMessage(messages.testsByStatus):
        statusImage = data;
        break;
      case formatMessage(messages.testsType):
        testTypeImage = data;
        break;
      default:
        break;
    }
  };

  const data = [
    {
      name: formatMessage(messages.registeredDonors),
      count: donorsCount
    },
    {
      name: formatMessage(messages.donations),
      count: donationsCount
    },
    {
      name: formatMessage(messages.bloodProducts),
      count: bloodProductCount
    },
    {
      name: formatMessage(messages.requests),
      count: bloodProductReqCount
    },
    {
      name: formatMessage(messages.appointments),
      count: appointmentsCount
    }
  ];

  const handleClose = () => {
    setShowFilter(false);
  };

  const pdfExport = () => {
    const doc = new JsPDF();
    const xOffset = doc.internal.pageSize.width / 2;
    let xPos = 10;
    let yPos = 15;
    doc.text(`${messages.filterContext.defaultMessage} (${currentMonth})`, xOffset, 10, { align: 'center' });
    doc.setFont('Helvetica', '');
    doc.setFontSize(6);
    doc.text(`${new Date().toDateString()}`, 190, 9, { align: 'center' });

    data.forEach((item, index) => {
      const rectWidth = 60;
      const rectHeight = 20;

      doc.roundedRect(xPos, yPos, rectWidth, rectHeight, 1, 1);
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(18);
      doc.text(`${item.count}`, xPos + 5, yPos + 8);
      doc.setFontSize(9);
      doc.setFont('Helvetica', '');
      doc.text(`${item.name}`, xPos + 5, yPos + 16);

      xPos += rectWidth + 5;

      if ((index + 1) % 3 === 0) {
        xPos = 10;
        yPos += rectHeight + 4;
      }
    });
    doc.roundedRect(10, 63, 60, 52, 1, 1);
    doc.text(`${messages.donorsByGender.defaultMessage}`, 17, 70);
    doc.addImage(genderImage, 'PNG', 19, 72, 38, 40);

    doc.roundedRect(75, 63, 125, 52, 1, 1);
    doc.text(`${messages.noOfdonations.defaultMessage}`, 112, 70);
    doc.addImage(donationImage, 'PNG', 90, 72, 95, 41);

    doc.roundedRect(10, 119, 60, 52, 1, 1);
    doc.text(`${messages.donorByBloodGroup.defaultMessage}`, 14, 125);
    doc.addImage(bloodGroupImage, 'PNG', 19, 128, 50, 41);

    doc.roundedRect(75, 119, 60, 52, 1, 1);
    doc.text(`${messages.donorByAge.defaultMessage}`, 83, 125);
    doc.addImage(donorsAgeImage, 'PNG', 84, 128, 50, 41);

    doc.roundedRect(140, 119, 60, 52, 1, 1);
    doc.text(`${messages.testsByStatus.defaultMessage}`, 147, 125);
    doc.addImage(statusImage, 'PNG', 146, 128, 45, 41);

    doc.roundedRect(10, 175, 190, 64, 1, 1);
    doc.text(`${messages.testsType.defaultMessage}`, 87, 182);
    doc.addImage(testTypeImage, 'PNG', 25, 186, 170, 52);

    doc.save('dashboard.pdf');
  };

  const handleClick = () => {
    setFilteredValue(value);
    setSelectedColFilters(value);
    setShowFilter(false);
  };

  const renderChartComponent = (item, index) => {
    switch (item) {
      case formatMessage(messages.donorsByGender):
        return (
          <Col key={index} md={8} sm={24}>
            <DonorsByGender
              title={messages.donorsByGender}
              donorsChildData={(data) => setDonorsData(data)}
              onChartImageReady={(data) => setImage(data, formatMessage(messages.donorsByGender))}
            />
          </Col>
        );
      case formatMessage(messages.noOfdonations):
        return (
          <Col key={index} md={16} sm={24}>
            <BloodDonations
              title={messages.noOfdonations}
              donationsChildData={(data) => setDonationData(data)}
              onChartImageReady={(data) => setImage(data, formatMessage(messages.noOfdonations))}
            />
          </Col>
        );
      case formatMessage(messages.donorByBloodGroup):
        return (
          <Col key={index} md={8} sm={24}>
            <DonorsByBloodGroup
              title={messages.donorByBloodGroup}
              bloodGroupChildData={(data) => setBloodGroupData(data)}
              onChartImageReady={(data) => setImage(data, formatMessage(messages.donorByBloodGroup))}
            />
          </Col>
        );
      case formatMessage(messages.donorByAge):
        return (
          <Col key={index} md={8} sm={24}>
            <DonorsByAge
              title={messages.donorByAge}
              donorsAgeChildData={(data) => setDonorsAgeData(data)}
              onChartImageReady={(data) => setImage(data, formatMessage(messages.donorByAge))}
            />
          </Col>
        );
      case formatMessage(messages.testsByStatus):
        return (
          <Col key={index} md={8} sm={24}>
            <TestsByStatus
              title={messages.testsByStatus}
              statusChildData={(data) => setStatusData(data)}
              onChartImageReady={(data) => setImage(data, formatMessage(messages.testsByStatus))}
            />
          </Col>
        );
      case formatMessage(messages.testsType):
        return (
          <Col key={index} sm={24}>
            <TestsType
              title={messages.testsType}
              testTypeChildData={(data) => setTestTypeData(data)}
              onChartImageReady={(data) => setImage(data, formatMessage(messages.testsType))}
            />
          </Col>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const subscription = getCollection(db, 'donor')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        setDonorsCount(getCurrentMonthData(entries).length);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    const subscription = getCollection(db, 'donation')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        setDonationsCount(getCurrentMonthData(entries).length);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    const subscription = getCollection(db, 'transfusion')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        const groupedData = groupBy(getCurrentMonthData(entries), 'bloodProductType.name');
        const dataPoints = Object.keys(groupedData);
        const formattedData = dataPoints.map((name) => ({
          name: name.toUpperCase(),
          value: (groupedData[name] && groupedData[name].length) || '0'
        }));
        setBloodProduct(formattedData.reduce((acc, dataPoint) => acc + dataPoint.value, 0));
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    const subscription = getCollection(db, 'request')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        setBloodProductReqCount(getCurrentMonthData(entries).length);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    const subscription = getCollection(db, 'appointment')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        setAppointmentsCount(getCurrentMonthData(entries).length);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  useEffect(() => {
    setBloodGroupKeys(bloodGroupData.key);
    setBloodGroupValues(bloodGroupData.values);
  }, [bloodGroupData]);

  useEffect(() => {
    setDonorsAgeKeys(donorsAgeData.key);
    setDonorsAgeValues(donorsAgeData.values);
  }, [donorsAgeData]);

  useEffect(() => {
    setStatusKeys(statusData.key);
    setStatusValues(statusData.values);
  }, [statusData]);

  useEffect(() => {
    setTestTypeKeys(testTypeData.key);
    setTestTypeValues(testTypeData.values);
  }, [testTypeData]);

  const [donationsKey, setDonationsKey] = useState([]);
  const [donationsMaleValues, setDonationsMaleValues] = useState([]);
  const [donationsFemaleValues, setDonationsFemaleValues] = useState([]);
  useEffect(() => {
    setDonationsKey(donationData.key);
    setDonationsMaleValues(donationData.maleData);
    setDonationsFemaleValues(donationData.femaleData);
  }, [donationData]);

  const getCount = (data) => {
    return Object.keys(data.data).map((name) => (name + ' - ' + data.data[name].length));
  };

  const getDonationCount = () => {
    return `Male ${donationsMaleValues[0]}, Female ${donationsFemaleValues[0]}`;
  };

  useEffect(() => {
    setCsvData([
      ...data,
      ...donorsData.map((data) => ({
        name: `${data.name} Donors data`,
        count: data.value
      }))
    ]);

    if (bloodGroupValues && donorsAgeValues && statusValues && testTypeValues && donationsKey) {
      setCsvData([
        ...data,
        ...donorsData.map((data) => ({
          name: `${data.name} Donors data`,
          count: data.value
        })),
        ...bloodGroupValues.map((data, index) => ({
          name: `Blood group - ${bloodGroupKeys[index]}`,
          count: data
        })),
        ...donorsAgeValues.map((data, index) => ({
          name: `Age - ${donorsAgeKeys[index]}`,
          count: data
        })),
        ...statusValues.map((data, index) => ({
          name: `Test status - ${statusKeys[index]}`,
          count: `Pass - ${data.pass}, Fail - ${data.fail}`
        })),
        ...testTypeValues.map((data, index) => ({
          name: `Test type - ${testTypeKeys[index]}`,
          count: getCount(data)
        })),
        ...donationsKey.map((data) => ({
          name: `Blood donation - ${data}`,
          count: getDonationCount()
        }))
      ]);
    }
    // eslint-disable-next-line
  }, [donorsData,
    bloodGroupKeys,
    bloodGroupValues,
    donorsAgeKeys,
    donorsAgeValues,
    statusKeys,
    statusValues,
    testTypeKeys,
    testTypeValues,
    donationsKey
  ]);

  const renderDropdown = (props, ref) => {
    return (
      <DropdownButton style={{ backgroundColor: '#B02C17', color: '#FFF' }} {...props} ref={ref}>
        <img src={exportIcon} alt='download' />
        <span>Export</span>
      </DropdownButton>
    );
  };

  return (
    <FlexboxGrid style={{ padding: '5px 0 15px' }}>
      <FlexboxGrid.Item style={{ flex: 1 }}>
        <BreadCrumb crumbs={['dashboard']} />
        <MarginDiv style={{ ...commonStyles, justifyContent: 'space-between', paddingLeft: 5 }}>
          <div>
            <H3 style={{ fontWeight: 700 }}>{formatMessage(messages.title)}</H3>
            <H5 style={{ marginTop: 24 }}>{formatMessage(messages.filterContext)} ({currentMonth})</H5>
          </div>
          <div style={{ display: 'flex' }}>
            <PrimaryHoloButton onClick={() => setShowFilter(true)} style={{ padding: '0.4rem 1rem' }}>
              <img style={{ marginRight: 6 }} src={filterIcon} alt='filter' />
              {formatMessage(messages.filter)}
            </PrimaryHoloButton>
            <FlexboxGrid.Item>
              <DropdownList renderToggle={renderDropdown}>
                <CSVLink
                  data-testid='csv'
                  filename='CSV'
                  data={csvData}
                >
                  <Dropdown.Item>
                    <FormattedMessage
                      id='data.dashboard.export.csv'
                      defaultMessage='CSV & Excel'
                    />
                  </Dropdown.Item>
                </CSVLink>
                <Hr />
                <Dropdown.Item data-testid='pdf' onClick={pdfExport}>
                  <FormattedMessage
                    id='data.dashboard.export.pdf'
                    defaultMessage='PDF'
                  />
                </Dropdown.Item>
              </DropdownList>
            </FlexboxGrid.Item>
          </div>
        </MarginDiv>
        <MarginDiv>
          <Grid fluid>
            <Row style={rowStyle} gutter={16}>
              {data.filter(item => filteredValue.includes(item.name))
                .map(item => (
                  <Col key={item.name} md={8} sm={24}>
                    <StyledPanel>
                      <H2 style={{ fontWeight: 700, color: '#010005' }}>{item.count}</H2>
                      <StyledSubHeading style={{ marginBottom: 0 }}>{item.name}</StyledSubHeading>
                    </StyledPanel>
                  </Col>
                )
                )}
            </Row>
          </Grid>
          <Grid fluid>
            <Row style={rowStyle} gutter={16}>
              {checkData.map((item, index) => (
                selectedColFilters.includes(item) && (
                  renderChartComponent(item, index)
                )
              ))}
            </Row>
          </Grid>
          {showFilter && (
            <OpenModal
              onClose={handleClose}
              size='md'
              title={
                <FormattedMessage
                  id='dashboard.filter.title'
                  defaultMessage='Filter'
                />
              }
            >
              <Modal.Body style={{ marginBottom: 30 }}>
                <Grid fluid>
                  <Row>
                    <Col xs={24}>
                      <Col xs={24}>
                        <StyledCheckbox
                          indeterminate={value.length > 0 && value.length < checkData.length}
                          checked={value.length === checkData.length}
                          onChange={handleCheckAll}
                        >
                          Select all
                        </StyledCheckbox>
                        <StyledDivider style={{ marginLeft: 10 }} />
                      </Col>
                    </Col>
                    <Col xs={24}>
                      <CheckboxGroup
                        inline
                        style={{ flexWrap: 'wrap' }}
                        name='checkboxList' value={value} onChange={handleChange}
                      >
                        {checkData.map(item => (
                          <Col key={item} xs={12}>
                            <StyledCheckbox value={item}>
                              {item}
                            </StyledCheckbox>
                          </Col>
                        ))}
                      </CheckboxGroup>
                    </Col>
                    <Col xs={24}>
                      <Col xs={24}>
                        <Col xs={24}>
                          <PrimaryButton
                            style={{ ...btnStyle, width: '40%', justifyContent: 'center', marginTop: 10 }}
                            onClick={handleClick}
                          >
                            <FormattedMessage
                              id='dashboard.filter.title'
                              defaultMessage='Filter'
                            />
                          </PrimaryButton>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Grid>
              </Modal.Body>
            </OpenModal>
          )}
        </MarginDiv>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Dashboard;
