import ArrowBackIcon from '@rsuite/icons/ArowBack';
import { groupBy } from 'lodash';
import React from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import { MarginDiv } from '../../containers/styled/layout';
import { useDatabase } from '../../context/database';
import { getCollection } from '../../rxdb/collections';
import { permissions } from '../../utils/auth';
import DataEntity from '../generic/DataEntity';
import FormComponent from './subcomponents/BloodProductTransferForm';
// import ViewComponent from './subcomponents/BloodProductTransferView';
// import FacilityTypeView from './subcomponents/BloodProductTransferView';

const StyledDiv = styled.div`
    padding: 20px;
    color: #b02c17;
    display: inline-block;
    cursor: pointer;
`;

const messages = defineMessages({
  title: {
    id: 'inventory.bloodProductTransfer.title',
    defaultMessage: 'Blood product transfer'
  },
  count: {
    id: 'facility.type.count.label',
    defaultMessage: 'Blood products'
  },
  searchPlaceHolder: {
    id: 'facility.type.search.placeholder',
    defaultMessage: 'Search'
  },
  name: {
    id: 'facility.name',
    defaultMessage: 'Facility'
  },
  quantity: {
    id: 'facility.quantity',
    defaultMessage: 'Quantity (Pcs)'
  },
  type: {
    id: 'facility.type.details',
    defaultMessage: 'Facility type'
  },
  add: {
    id: 'facility.type.add.transfer.button',
    defaultMessage: 'Transfer blood products'
  }
});

const ADD = permissions.facilityType.add;

/**
 * Facility Type screen
 *
 * List of available facility types.
 *
 * @component
 * @example
 * return (
 *    <FacilityType />
 * );
 */
const ViewInventory = ({ selectedData, goBack }) => {
  const db = useDatabase();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!selectedData) return;
    const subscription = getCollection(db, 'bloodProduct')
      .find()
      .where({ isActive: true, 'facility.id': selectedData.id })
      .$
      .subscribe((entries) => {
        const groupBloodProductType = groupBy(entries, 'bloodProductType.id');
        const formattedData = Object.keys(groupBloodProductType).map((key) => {
          const firstRow = groupBloodProductType[key][0];
          return {
            id: key,
            facility: firstRow.facility,
            name: firstRow.bloodProductType.name,
            quantity: groupBloodProductType[key].length,
            status: 'sufficient',

            // this is for expandable table having table as child
            childrenColumn: [
              { dataKey: 'name', label: 'Blood product name' },
              { dataKey: 'volume', label: 'Volume' },
              { dataKey: 'date', label: 'Expiry date' }
            ]
          };
        });
        setData(formattedData);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, selectedData]);

  return (
    <MarginDiv data-testid='bloodProduct-transfer'>
      <StyledDiv onClick={goBack}>
        <ArrowBackIcon /> <span style={{ marginLeft: '10px' }}>Return back to inventory</span>
      </StyledDiv>
      <DataEntity
        crumbs={['inventoryGroup', 'bloodProductTransfer']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'quantity', label: messages.quantity }
        ]}
        isStatus
        addPerm={ADD}
        FormComponent={FormComponent}
        messages={{
          ...messages,
          title: {
            id: 'inventory.bloodProductTransfer.title',
            defaultMessage: selectedData.name
          }
        }}
        // this is for expandable table having table as child
        isChildPage

      />

    </MarginDiv>
  );
};

export default ViewInventory;
