import { FormattedMessage } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import Modal from 'rsuite/Modal';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { ViewLabel, ViewLabelValue } from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Facility Type View screen
 *
 * Facility type view to display each type in-detail.
 *
 * @component
 * @param {object} item                Facility Type data
 * @param {boolean} handleClose        handles modal close event
 * @returns type view modal.
 *
 */
const FacilityTypeView = ({ item, handleClose }) => {
  return (
    <OpenModal
      data-testid='view-facility-type'
      onClose={handleClose}
      size='md'
      title={
        <FormattedMessage
          id='facility.type.details.title'
          defaultMessage='Facility Type Details'
        />
    }
    >
      <Modal.Body style={{ marginBottom: 30 }}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='facility.type.view.label.id'
                  defaultMessage='Type ID'
                />
              </ViewLabel>
              <ViewLabelValue>{item.id}</ViewLabelValue>
            </Col>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='facility.type.view.label.name'
                  defaultMessage='Type Name'
                />
              </ViewLabel>
              <ViewLabelValue>{item.name}</ViewLabelValue>
            </Col>
          </Row>
          <ViewMarginDiv />
          <Row>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='facility.type.view.label.role'
                  defaultMessage='Role'
                />
              </ViewLabel>
              <ViewLabelValue>{item.role ? item.role.name : '---'}</ViewLabelValue>
            </Col>
            <Col xs={12}>
              <ViewLabel>
                <FormattedMessage
                  id='facility.type.view.label.details'
                  defaultMessage='Details'
                />
              </ViewLabel>
              <ViewLabelValue>{item.details}</ViewLabelValue>
            </Col>
          </Row>
          <ViewMarginDiv />
        </Grid>
        <AuditFieldsView item={item} />
      </Modal.Body>
    </OpenModal>
  );
};

export default FacilityTypeView;
