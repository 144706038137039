import React from 'react';
import { Table } from 'rsuite';

import { useDatabase } from '../../context/database';
import { getCollection } from '../../rxdb/collections';

const { HeaderCell, Cell } = Table;

const SimpleDataTable = ({ data, column, isStatus }) => {
  const db = useDatabase();
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    if (!data) return;
    const subscription = getCollection(db, 'bloodProduct')
      .find()
      .where({
        isActive: true,
        'facility.id': data.facility.id,
        'bloodProductType.id': data.id
      })
      .$
      .subscribe((entries) => {
        const docs = entries.map((item) => {
          return {
            id: item.id,
            name: item.bloodProductType.name + `(${item.donation.donor.donorCode})`,
            date: item.expiryDate,
            volume: item.volume
          };
        });
        setTableData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, data]);

  return (
    <div>
      <Table height={500} data={tableData}>

        {column.length > 0 && column.filter((cols) => cols.dataKey !== 'status').map(col => {
          return (
            <Table.Column align='left' flexGrow={1} resizable key={col.dataKey}>
              <HeaderCell>{col.label}</HeaderCell>
              <Cell dataKey={col.dataKey} />
            </Table.Column>
          );
        })}

      </Table>
    </div>
  );
};

export default SimpleDataTable;
