import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for adverse event data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listAdverseEvents(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode
        severity
        status
        details
        transfusion {
          id
          icode
          dueTime
          status
          hospital { id name address }
          bloodProductType { id name shelfLife shelfLifeUnit }

          bloodProduct {
            id icode volume bloodType expiryDate
            bloodProductType { id name shelfLife shelfLifeUnit }
            donation { id }
          }
          hospital {
            id name address
            bloodBank { id name address stockUnit { id name address } }
          }
          patient { id patientCode fullName personName gender bloodType }
        }
        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for adverse event data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertAdverseEvent(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'severity')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationStringValue(doc, 'details')}
          ${getMutationNestedValue(doc, 'transfusion')}
          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize adverse event data with GraphQL endpoint
 */
export const syncAdverseEvent = (...args) => {
  return buildReplication(
    ...args,
    'adverseEvent',
    { pullBuilder, pullQuery: 'listAdverseEvents' },
    { pushBuilder }
  );
};
