import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { deleteConsumable } from '../../../controllers/consumable/consumable';
import { getCollection } from '../../../rxdb/collections';
import { permissions } from '../../../utils/auth';
import DataEntity from '../../generic/DataEntity';
import FormComponent from './subcomponents/DonationScheduleForm';
import ViewComponent from './subcomponents/DonationScheduleView';

const messages = defineMessages({
  title: {
    id: 'configuration.donation.schedule.title',
    defaultMessage: 'Donation schedules'
  },
  count: {
    id: 'configuration.donation.schedule.count.label',
    defaultMessage: 'Schedules'
  },
  searchPlaceHolder: {
    id: 'configuration.donation.schedule.search.placeholder',
    defaultMessage: 'Search'
  },
  name: {
    id: 'configuration.donation.schedule.header.name',
    defaultMessage: 'Blood donation centre'
  },
  schedule: {
    id: 'configuration.donation.schedule.header.schedule',
    defaultMessage: 'Schedule'
  },
  dStatus: {
    id: 'configuration.donation.schedule.header.status',
    defaultMessage: 'Status'
  },

  add: {
    id: 'configuration.donation.schedule.add.button',
    defaultMessage: 'Create schedule'
  }
});

const ADD = permissions.consumable.add;
const EDIT = permissions.consumable.edit;
const DELETE = permissions.consumable.del;

/**
 * Facility Type screen
 *
 * List of available facility types.
 *
 * @component
 * @example
 * return (
 *    <Consumable />
 * );
 */
const DonationSchedule = () => {
  const db = useDatabase();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const subscription = getCollection(db, 'consumable')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        // const docs = entries.map((entry) => {
        //   return {
        //     id: entry.id,
        //     name: entry.name,
        //     serialNumber: entry.serialNumber,
        //     quantity: 20,
        //     manufacturerDate: entry.manufacturerDate,
        //     expiryDate: entry.expiryDate,
        //     item: entry.item
        //   };
        // });
        setData([
          {
            id: 1,
            name: 'Gorrobawa Blood Bank',
            schedule: '5 Schedule',
            status: 'Active'
          },
          {
            id: 2,
            name: 'Tarauni Primary Health center',
            schedule: '5 Schedule',
            status: 'Dissbled'
          }
        ]);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  return (
    <MarginDiv data-testid='donation-schedule'>
      <DataEntity
        crumbs={['configurationGroup', 'donationSchedule']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'schedule', label: messages.schedule },
          { dataKey: 'status', label: messages.dStatus }
        ]}
        addPerm={ADD}
        editPerm={EDIT}
        FormComponent={FormComponent}
        deletePerm={DELETE}
        onDelete={deleteConsumable}
        ViewComponent={ViewComponent}
        messages={messages}
        isStatus
      />

    </MarginDiv>
  );
};

export default DonationSchedule;
